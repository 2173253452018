import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, fromEvent, map, startWith } from "rxjs";

@Injectable()
export class WindowSizeService {
    private _height$: ReplaySubject<number> = new ReplaySubject<number>(1);
    private _width$: ReplaySubject<number> = new ReplaySubject<number>(1);

    private _currentWidth: number = 0;
    private _currentHeight: number = 0;

    constructor() {
        this.listenForResize();
    }

    public listenForResize(): void {
        this.setupViewableContainerCssVar(window.innerHeight);

        const resizeObservable$ = fromEvent(window, "resize");
        resizeObservable$
            .pipe(
                map((event: any) => [event.target.innerWidth, event.target.innerHeight]),
                startWith([window.innerWidth, window.innerHeight])
            )
            .subscribe(([width, height]) => {
                if (width !== this._currentWidth) {
                    this.setWidth(width);
                }

                if (height !== this._currentHeight) {
                    this.setHeight(height);
                    this.setupViewableContainerCssVar(height);
                }
            });
    }

    public getWidth(): Observable<number> {
        return this._width$.asObservable();
    }

    public getHeight(): Observable<number> {
        return this._height$.asObservable();
    }

    private setWidth(newWidth: number): void {
        this._currentWidth = newWidth;
        this._width$.next(newWidth);
    }

    private setHeight(newHeight: number): void {
        this._currentHeight = newHeight;
        this._height$.next(newHeight);
    }

    private setupViewableContainerCssVar(height: number) {
        const viewableHeight = `${window.innerHeight}px`;
        const rootEl = document.documentElement;
        rootEl.style.setProperty("--app-height", viewableHeight);
    }
}
