<a
    *ngIf="isExternal; else internalLink"
    class="arrow-button"
    [href]="path ?? ''"
    target="_blank"
    (click)="handleClick($event)"
>
    <ng-template *ngTemplateOutlet="buttonContents"></ng-template>
</a>

<ng-template #internalLink>
    <button
        class="arrow-button"
        [routerLink]="path"
        (click)="!disabled && handleClick($event)"
        [attr.disabled]="disabled ? true : null"
    >
        <ng-template *ngTemplateOutlet="buttonContents"></ng-template>
    </button>
</ng-template>

<ng-template #buttonContents let-content>
    <span *ngIf="arrowDirection === 'left'" class="arrow-icon"> </span>
    <span class="arrow-button__text" [ngClass]="{ 'arrow-button__text--right': arrowDirection === 'right' }">{{
        text
    }}</span>
    <span *ngIf="arrowDirection === 'right'" class="arrow-icon arrow-icon--right"> </span>
</ng-template>
