import { CommonModule } from "@angular/common";
import { ChangeDetectorRef, Component, DestroyRef, OnInit, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NavigationEnd, Router, RouterModule } from "@angular/router";
import { LoadingSpinnerComponent } from "./components/loading-spinner/loading-spinner.component";
import { AnalyticsService } from "./core/services/analytics.service";
import { LoadingService } from "./core/services/loading.service";

@Component({
    standalone: true,
    imports: [CommonModule, RouterModule, LoadingSpinnerComponent],
    selector: "grainger-root",
    templateUrl: "./app.component.html",
    styleUrl: "./app.component.scss"
})
export class AppComponent implements OnInit {
    title = "Grainger Facilities";

    private router = inject(Router);
    private loadingService = inject(LoadingService);
    private destroy$ = inject(DestroyRef);
    private changeDetector = inject(ChangeDetectorRef);
    private analyticsService = inject(AnalyticsService);

    public isLoading: boolean;

    ngOnInit(): void {
        this.analyticsService.initializeGoogleAnalytics();

        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }

            this.analyticsService.disableDefaultPageView();

            this.loadingService
                .getLoadingStatus()
                .pipe(takeUntilDestroyed(this.destroy$))
                .subscribe((isLoading) => {
                    this.isLoading = isLoading;
                    this.changeDetector.detectChanges();
                });

            window.scrollTo({ top: 0, behavior: "smooth" });
        });
    }
}
