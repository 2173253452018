import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BaseTabComponent } from "../components/for-extension-only/base-tab/base-tab.component";
import { IconListItemComponent } from "../components/icon-list-item/icon-list-item.component";
import { MobileDepartmentHeaderComponent } from "../components/mobile-department-header/mobile-department-header.component";
import { CustomAnimations } from "../core/animations/animations";
import { InlineInputDirective } from "../core/directives/inline-input.directive";
import { ArrayToColumnsPipe } from "../core/pipes/array-to-columns.pipe";
import { CapitalizeFirstLetterPipe } from "../core/pipes/capitalize-first-letter.pipe";

@Component({
    selector: "grainger-challenges",
    standalone: true,
    imports: [
        CommonModule,
        ArrayToColumnsPipe,
        RouterModule,
        MobileDepartmentHeaderComponent,
        CapitalizeFirstLetterPipe,
        InlineInputDirective,
        IconListItemComponent
    ],
    templateUrl: "./challenges.component.html",
    styleUrl: "./challenges.component.scss",
    animations: [CustomAnimations.fadeIn("0.3s")]
})
export class ChallengesComponent extends BaseTabComponent {}
