import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "grainger-accordion-item",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./accordion-item.component.html",
    styleUrl: "./accordion-item.component.scss"
})
export class AccordionItemComponent {
    @Output() selectedItem: EventEmitter<void> = new EventEmitter<void>();

    @Input() isOpen: boolean = false;

    public selectItem(): void {
        this.selectedItem.emit();
    }
}
