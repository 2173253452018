import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { UpdateUserProfileDto } from "@grainger/common/dto/update-user-profile.dto";
import { plainToInstance } from "class-transformer";
import { validate } from "class-validator";
import { switchMap } from "rxjs";
import { AuthService } from "../services/auth.service";

export class ProfileGuard {
    static isValid: CanActivateFn = () => {
        const authService = inject(AuthService);
        const router = inject(Router);
        return authService.user$.pipe(
            switchMap(async (user) => {
                if (!user) {
                    return false;
                }

                // Validate user profile dto
                const dto = plainToInstance(UpdateUserProfileDto, user);

                const errors = await validate(dto);
                if (errors.length) {
                    router.navigate(["/complete-profile"]);
                    return false;
                }
                return true;
            })
        );
    };
}
