<ng-container *ngIf="facility; else noFacilityFound">
    <div
        class="page__background"
        [ngStyle]="{
            backgroundImage: 'url(' + 'assets/dollhouses/mobile/' + facility.urlSlug + '.png)'
        }"
    >
        <div class="screen"></div>
    </div>

    <div class="page facility">
        <header class="page__header">
            <button class="btn nav-button-toggle" (click)="openGlobalMenu()">
                <img class="nav-button-toggle__icon" src="assets/ui/icon-hamburger.svg" alt="Navigation Menu Toggle" />
            </button>

            <div class="logo">
                <img src="assets/images/logo.svg" alt="Grainger Logo" class="logo__img" />
            </div>
        </header>

        <div class="page__content">
            <div class="page__details">
                <h1 class="page__heading">Welcome!</h1>
            </div>

            <div *ngIf="facility?.pillars?.length" class="accordion">
                <ng-container *ngFor="let pillar of facility?.pillars; let i = index">
                    <grainger-accordion-item
                        class="accordion-item"
                        [isOpen]="currentAccordionIndex === i && currentAccordionIndex !== null"
                        (selectedItem)="
                            trackClick(
                                'Content ' +
                                    (i === currentAccordionIndex ? 'collapsed' : 'expanded') +
                                    ' - ' +
                                    pillar.name
                            );
                            updateAccordionIndex(i)
                        "
                    >
                        <ng-container accordion-heading>{{ pillar.name }}</ng-container>

                        <ng-container accordion-content>
                            <h3 class="accordion-content__subtitle">{{ pillar.name }}</h3>

                            <ul *ngIf="pillar.displayDepartments as depts" class="accordion-content__links">
                                <grainger-icon-list-item
                                    *ngFor="let dept of depts"
                                    class="accordion-content__link-item"
                                    (click)="
                                        trackClick(
                                            'Clicked department link for pillar: ' + pillar.name + ' | ' + dept.name
                                        )
                                    "
                                    [link]="{
                                        name: dept.name,
                                        url: 'department/' + dept.urlSlug
                                    }"
                                ></grainger-icon-list-item>
                            </ul>
                        </ng-container>
                    </grainger-accordion-item>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #noFacilityFound>
    <h1>No Facility Found</h1>
</ng-template>
