import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { IFacility } from "@grainger/common/definitions/facility";
import { AccordionControl } from "../../core/interfaces/accordion-control.interface";
import { AnalyticsService } from "../../core/services/analytics.service";
import { AccordionItemComponent } from "../accordion-item/accordion-item.component";
import { IconListItemComponent } from "../icon-list-item/icon-list-item.component";

@Component({
    selector: "grainger-facility-mobile",
    standalone: true,
    imports: [CommonModule, AccordionItemComponent, IconListItemComponent],
    templateUrl: "./facility-mobile.component.html",
    styleUrl: "./facility-mobile.component.scss"
})
export class FacilityMobileComponent implements AccordionControl {
    public currentAccordionIndex: number | null;
    private analyticsService = inject(AnalyticsService);

    @Output() menuOpened: EventEmitter<void> = new EventEmitter<void>();

    @Input() public facility: IFacility | null;

    public openGlobalMenu(): void {
        this.menuOpened.emit();
    }

    public updateAccordionIndex(index: number): void {
        this.currentAccordionIndex = this.currentAccordionIndex === index ? null : index;
    }

    public trackClick(label: string): void {
        this.analyticsService.trackInteraction(
            {
                action: "click",
                location: "Facility Page",
                category: "button",
                label
            },
            { facility: this.facility?.name }
        );
    }
}
