<div class="accordion">
    <div class="accordion__header">
        <h2 *ngIf="heading" class="accordion__heading section__heading section__heading--small">
            {{ heading }}
        </h2>

        <div class="accordion-legend">
            <div class="accordion-legend__item">
                <div class="c-legend-icon c-legend-icon--service">
                    <img src="assets/ui/icon-service-red.png" alt="Service Icon" class="accordion-legend__img" />
                </div>
                <span class="accordion-legend__text">Service</span>
            </div>

            <div class="accordion-legend__item">
                <div class="c-legend-icon c-legend-icon--product">
                    <img src="assets/ui/icon-product-red.png" alt="Product Icon" class="accordion-legend__img" />
                </div>
                <span class="accordion-legend__text">Product</span>
            </div>
        </div>
    </div>
    <ng-content></ng-content>
</div>
