/**
 * This component is meant to be extended and not used directly
 */

import { CommonModule } from "@angular/common";
import { AfterViewInit, ChangeDetectorRef, Component, DestroyRef, OnDestroy, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Router } from "@angular/router";
import { ILinkableResource } from "@grainger/common/definitions/common";
import { IDepartmentUi, IEmbed } from "@grainger/common/definitions/department";
import { tap } from "rxjs";
import { AnalyticsService } from "../../../core/services/analytics.service";
import { DepartmentService } from "../../../core/services/department.service";
import { EditService } from "../../../core/services/edit.service";

@Component({
    selector: "grainger-base-department",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./base-department.component.html",
    styleUrl: "./base-department.component.scss"
})
export class BaseDepartmentComponent implements AfterViewInit, OnDestroy {
    private activatedRoute = inject(ActivatedRoute);
    public router = inject(Router);
    private changeDetector = inject(ChangeDetectorRef);
    protected analyticsService = inject(AnalyticsService);
    private destroyRef = inject(DestroyRef);
    private editService = inject(EditService);
    private departmentsService = inject(DepartmentService);

    public department: IDepartmentUi | null = null;

    public departmentLinks: ILinkableResource[] = [];

    public canEdit = false;
    // Tabs visibility properties
    public canShowOverview = false;
    public canShowChallenges = false;
    public canShowSolutions = false;
    public canShowEmbeds = false;
    public canShowResources = false;
    public canShowInsights = false;

    ngAfterViewInit(): void {
        this.activatedRoute.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data) => {
            this.department = data["department"] ?? null;
            this.updateTabVisibility();

            this.changeDetector.detectChanges();
            this.departmentLinks = this.getNormalizedDepartmentLinks(
                this.department?.pillarInfo.displayDepartments || []
            );

            this.changeDetector.detectChanges();
        });

        this.editService.isEditing$
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                tap((canEdit) => {
                    this.canEdit = canEdit;
                    this.updateTabVisibility();
                })
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        // Disable edit mode when leaving component
        this.editService.disableEditMode();
    }

    public trackHeaderClick(label: string): void {
        this.analyticsService.trackInteraction(
            {
                action: "click",
                location: "Department Page Header",
                category: "button",
                label
            },
            {
                facility: this.department?.facility?.name,
                pillar: this.department?.pillarInfo.pillarName,
                department: this.department?.name
            }
        );
    }

    public trackTabClick(label: string, location?: string): void {
        this.analyticsService.trackInteraction(
            {
                action: "click",
                location: location
                    ? `Department Page - ${location.charAt(0).toUpperCase() + location.slice(1)}`
                    : "Department Page",
                category: "button",
                label
            },
            {
                facility: this.department?.facility?.name,
                pillar: this.department?.pillarInfo.pillarName,
                department: this.department?.name
            }
        );
    }

    private updateTabVisibility(): void {
        this.canShowOverview = this.canEdit || this.getOverviewVisibility();
        this.canShowChallenges = this.canEdit || this.getChallengesVisibility();
        this.canShowSolutions = this.canEdit || this.getSolutionsVisibility();
        this.canShowEmbeds = this.canEdit || this.getEmbedVisibility();
        this.canShowResources = this.canEdit || this.getResourcesVisibility();
        this.canShowInsights = this.canEdit || this.getInsightsVisibility();
    }

    private getOverviewVisibility(): boolean {
        const department = this.department;

        return (
            !!department?.dailyOperations ||
            !!department?.targetRolesAndResponsibilities ||
            !!department?.industryInfluencers?.length ||
            !!department?.elevatorPitch?.length
        );
    }

    private getChallengesVisibility(): boolean {
        const department = this.department;

        return (
            !!department?.challenges?.topIssuesDescription ||
            !!department?.challenges?.issues?.length ||
            !!department?.challenges?.examples?.length ||
            !!department?.challenges?.conversationQuestions?.length ||
            !!department?.challenges?.dvpPings?.length
        );
    }

    private getSolutionsVisibility(): boolean {
        const department = this.department;

        return !!department?.products?.length || !!department?.services?.length || !!department?.suppliers?.length;
    }

    private getEmbedVisibility(): boolean {
        const department = this.department;
        return !!department?.embeds && department.embeds.length > 0 && this.hasValidEmbedContent(department.embeds);
    }

    private getResourcesVisibility(): boolean {
        const department = this.department;

        return (
            !!department?.resources?.alignedLiterature.length ||
            !!department?.resources?.caseStudies.length ||
            !!department?.resources?.knowHowArticles.length ||
            !!department?.resources?.learnings.length ||
            !!department?.resources?.learnings.length
        );
    }

    private getInsightsVisibility(): boolean {
        const department = this.department;

        return !!department?.insights?.industryInsights.length || !!department?.insights?.competitorInsights.length;
    }

    private hasValidEmbedContent(embeds: IEmbed[]): boolean {
        return embeds.some((embed) => {
            return !!embed.name && ((embed.urls ?? []).length > 0 || !!embed.description);
        });
    }

    private getNormalizedDepartmentLinks(links: ILinkableResource[]): ILinkableResource[] {
        return links.map((link) => {
            return {
                ...link,
                url: "/department/" + link.url
            };
        });
    }

    public updateDepartmentDescription(value: string): void {
        this.departmentsService
            .updateDepartment(this.department!.urlSlug, {
                description: value
            })
            .subscribe((res) => {
                // TODO: Proeprly reload the department resolver
                window.location.reload();
            });
    }
}
