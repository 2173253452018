import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { ISupplier } from "@grainger/common/definitions/supplier";
import { ISupplierPagePostRequest } from "@grainger/common/definitions/supplier-page-request";
import { PageDto } from "@grainger/common/dto/page.dto";
import { Observable, catchError, tap } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class SupplierService {
    private http = inject(HttpClient);

    public searchSuppliers(options?: ISupplierPagePostRequest): Observable<PageDto<ISupplier> | null> {
        return this.http
            .post<PageDto<ISupplier>>(`${environment.baseUrl}/suppliers/search`, {
                ...options
            })
            .pipe(
                tap((response) => console.log(response)),
                catchError((error) => {
                    console.error(error.message);

                    throw new Error("Error fetching suppliers");
                })
            );
    }
}
