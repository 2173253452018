<grainger-form-page-wrapper>
    <h1 class="form__heading">Login</h1>
    <form *ngIf="form" class="form" [formGroup]="form">
        <div class="c-form-fields">
            <grainger-form-control class="form-control">
                <input type="text" formControlName="email" placeholder="Email" appInput="email" />
            </grainger-form-control>
            <grainger-form-control class="form-control">
                <input type="password" formControlName="password" placeholder="Password" appInput="password" />
            </grainger-form-control>
        </div>

        <div class="c-buttons">
            <div class="c-form-btn">
                <grainger-arrow-button
                    class="form-btn"
                    text="Log In"
                    [handleInParent]="true"
                    (clicked)="logIn()"
                    [disabled]="isLoading"
                    arrowDirection="right"
                ></grainger-arrow-button>
            </div>

            <div class="c-forgot-password">
                <a
                    [routerLink]="['/', 'forgot-password']"
                    class="forgot-password"
                    (click)="trackClick('Clicked forgot password', 'Login Page')"
                    ><span class="forgot-password__text">Forgot password</span
                    ><span class="forgot-password__question-mark">?</span></a
                >
            </div>

            <div class="form-change-link">
                <span class="form-change-link__label">Don't have an account yet?</span>
                <a
                    class="form-change-link__text"
                    [routerLink]="['/', 'register']"
                    (click)="trackClick('Clicked sign up', 'Login Page')"
                    >Sign up</a
                >
            </div>
        </div>

        <p *ngIf="hasSubmissionError" class="submission-error">
            {{ submissionError }}
        </p>
    </form>
</grainger-form-page-wrapper>
