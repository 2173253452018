<ng-container *ngIf="!isEditing">
    <ng-container *ngIf="link.url; else normalListItem">
        <ng-container *ngIf="isExternal; else internal">
            <a class="list-item" [href]="link.url" target="_blank" (click)="clicked.emit()">
                <div class="list-item__content">
                    <!-- <span class="list-item__icon" *ngIf="!canEdit">+</span> -->
                    <svg
                        class="list-item__icon delete"
                        alt="Delete"
                        *ngIf="!isNew && canEdit && !disableDelete"
                        (click)="deleteItem($event)"
                    >
                        <use xlink:href="assets/ui/icon-trash.svg#icon"></use>
                    </svg>
                    <svg
                        class="list-item__icon edit"
                        alt="Edit"
                        *ngIf="!isNew && canEdit"
                        (click)="toggleInlineEdit($event)"
                    >
                        <use xlink:href="assets/ui/icon-edit.svg#icon"></use>
                    </svg>
                    <span class="list-item__text">{{ link.name || link.url || "" }}</span>
                </div>
            </a>
        </ng-container>
        <ng-template #internal>
            <a
                class="list-item"
                routerLinkActive="active"
                [routerLink]="link.url"
                [routerLinkActiveOptions]="{ exact: activeRouteExactMatch }"
                (click)="clicked.emit()"
            >
                <div class="list-item__content">
                    <!-- <span class="list-item__icon" *ngIf="!canEdit">+</span> -->
                    <svg
                        class="list-item__icon delete"
                        alt="Delete"
                        *ngIf="!isNew && canEdit && !disableDelete"
                        (click)="deleteItem($event)"
                    >
                        <use xlink:href="assets/ui/icon-trash.svg#icon"></use>
                    </svg>
                    <svg
                        class="list-item__icon edit"
                        alt="Edit"
                        *ngIf="!isNew && canEdit"
                        (click)="toggleInlineEdit($event)"
                    >
                        <use xlink:href="assets/ui/icon-edit.svg#icon"></use>
                    </svg>
                    <span class="list-item__text">{{ link.name || link.url || "" }}</span>
                </div>
            </a>
        </ng-template>
    </ng-container>

    <ng-template #normalListItem>
        <div class="list-item no-link" (click)="isNew && toggleInlineEdit($event)">
            <div class="list-item__content">
                <!-- <span class="list-item__icon" *ngIf="!canEdit" [innerHtml]="listStyleType"></span> -->
                <svg
                    class="list-item__icon delete"
                    alt="Delete"
                    *ngIf="!isNew && canEdit && !disableDelete"
                    (click)="deleteItem($event)"
                >
                    <use xlink:href="assets/ui/icon-trash.svg#icon"></use>
                </svg>
                <svg
                    class="list-item__icon edit"
                    alt="Edit"
                    *ngIf="!isNew && canEdit"
                    (click)="toggleInlineEdit($event)"
                >
                    <use xlink:href="assets/ui/icon-edit.svg#icon"></use>
                </svg>
                <span class="list-item__text">{{ link.name || link.url || "" }}</span>
            </div>
        </div></ng-template
    >
</ng-container>

<form
    class="list-item list-item-edit"
    *ngIf="isEditing && inlineForm"
    [formGroup]="inlineForm"
    (keyup.esc)="toggleInlineEdit($event)"
    (keyup.enter)="saveInlineInput()"
    (ngSubmit)="saveInlineInput()"
>
    <div class="list-item__content" *ngIf="fields['name']">
        <span class="list-item__icon">Name:</span>
        <textarea
            formControlName="name"
            class="list-item__input"
            #nameInput
            [rows]="fields['name'] === true ? 1 : fields['name']"
        ></textarea>
    </div>
    <div class="list-item__content" *ngIf="fields['description']">
        <span class="list-item__icon">Description:</span>
        <textarea
            formControlName="description"
            class="list-item__input"
            [rows]="fields['description'] === true ? 1 : fields['description']"
        ></textarea>
    </div>
    <div class="list-item__content" *ngIf="fields['url']">
        <span class="list-item__icon">URL:</span>
        <textarea
            type="url"
            formControlName="url"
            class="list-item__input"
            [rows]="fields['url'] === true ? 1 : fields['url']"
        ></textarea>
    </div>
    <div class="inline-edit-buttons" (mousedown)="$event.preventDefault()">
        <button type="button" class="btn inline-edit-cancel" (click)="toggleInlineEdit($event)">Cancel</button>
        <button type="submit" class="btn inline-edit-button">Save</button>
    </div>
</form>
