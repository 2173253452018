<div (appClickOutside)="closeDropdown($event)" class="select" [ngClass]="{ 'select--active': isExpanded }">
    <div class="select-toggle form-field" (click)="toggleAccordion()" tabindex="0">
        <div class="c-select-toggle__label">
            <span
                class="select-toggle__label"
                [ngClass]="{
                    'select-toggle__label--placeholder': !value || isExpanded
                }"
                >{{ value && !isExpanded ? value : label }}</span
            >
        </div>

        <span class="select-toggle__icon" [ngClass]="{ 'select-toggle__icon--active': isExpanded }"> </span>
    </div>
    <div class="select__content">
        <div class="scroll-wrapper" #dropdownContainer>
            <ul class="select-options">
                <li
                    *ngFor="let option of options"
                    class="select-option"
                    [ngClass]="{
                        'select-option--active': value === option
                    }"
                    tabindex="0"
                    (keydown)="onKeyDown($event, option)"
                    (click)="selectionChanged(option)"
                >
                    {{ option }}
                </li>
            </ul>
        </div>
    </div>
</div>
