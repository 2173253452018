import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { catchError, throwError } from "rxjs";
import { ArrowButtonComponent } from "../../components/arrow-button/arrow-button.component";
import { BaseAuthFormComponent } from "../../components/base-auth-form/base-auth-form.component";
import { FormControlComponent } from "../../components/form-control/form-control.component";
import { FormPageWrapperComponent } from "../../components/form-page-wrapper/form-page-wrapper.component";
import { InputDirective } from "../../core/directives/input.directive";
import { CustomValidators } from "../../core/validators/custom-validators.validator";

@Component({
    selector: "grainger-forgot-password",
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        ArrowButtonComponent,
        FormPageWrapperComponent,
        ReactiveFormsModule,
        FormControlComponent,
        InputDirective
    ],
    templateUrl: "./forgot-password.component.html",
    styleUrl: "./forgot-password.component.scss"
})
export class ForgotPasswordComponent extends BaseAuthFormComponent implements OnInit {
    public submissionMessage: string = "";

    override ngOnInit(): void {
        super.ngOnInit();

        this.analyticsService.trackPageView({ pageTitle: "Forgot Password" });
    }

    protected initForm() {
        // Change the access modifier to public
        return this.fb.group({
            email: this.fb.control(null, [Validators.required, CustomValidators.email])
        });
    }

    public resetPassword() {
        if (this.isLoading) {
            return;
        }

        this.trackClick("Clicked submit", "Forgot Password Page");

        this.submissionMessage = "";
        this.hasSubmissionError = false;

        if (this.form.invalid) {
            this.form.markAllAsTouched();
            this.form.updateValueAndValidity();
            return;
        }

        this.authService
            .resetPasswordRequest(this.email.value)
            .pipe(
                catchError((err) => {
                    this.hasSubmissionError = true;
                    this.submissionError = err.message;
                    return throwError(() => "Error resetting password");
                })
            )
            .subscribe(() => {
                this.submissionMessage = "Email sent! Check your email for a reset link.";
            });
    }

    private get email() {
        return this.form.get("email") as FormControl;
    }
}
