<header class="subpage-header">
    <div class="subpage-header__left">
        <ng-content [slot]="left"></ng-content>
    </div>

    <div class="subpage-header__right">
        <grainger-arrow-button
            (clicked)="buttonClicked.emit()"
            *ngIf="!hasFloatingButton"
            [text]="button?.name || 'Back to Map'"
            [path]="button?.url"
        ></grainger-arrow-button>

        <grainger-dropdown-nav
            (clicked)="dropdownClicked.emit($event)"
            *ngIf="dropdownMenu && dropdownMenu.links.length && !hasFloatingButton"
            [dropdown]="dropdownMenu"
            position="below"
        >
            <button class="btn btn--plus-sign">+</button>
        </grainger-dropdown-nav>
    </div>
</header>

<div *ngIf="hasFloatingButton" class="floating-container">
    <div #floatingButton class="subpage-header__right">
        <grainger-arrow-button
            (clicked)="buttonClicked.emit()"
            class="floating-button"
            [text]="button?.name || 'Back to Map'"
            [path]="button?.url"
        ></grainger-arrow-button>

        <div class="c-floating-dropdown">
            <grainger-dropdown-nav
                (clicked)="dropdownClicked.emit($event)"
                *ngIf="dropdownMenu && dropdownMenu.links.length"
                [dropdown]="dropdownMenu"
                position="below"
            >
                <button class="btn btn--plus-sign">+</button>
            </grainger-dropdown-nav>
        </div>
        <div class="c-edit" *ngIf="hasEditModeToggle && isAdmin">
            <button class="btn btn--edit" title="Toggle Editor Mode" (click)="toggleEditMode()">
                <svg>
                    <use xlink:href="assets/ui/icon-edit.svg#icon" *ngIf="!isEditing"></use>
                    <use xlink:href="assets/ui/icon-edit-off.svg#icon" *ngIf="isEditing"></use>
                </svg>
            </button>
        </div>
    </div>
</div>

<grainger-edit-mode-popover *ngIf="editModePopoverVisible"></grainger-edit-mode-popover>
