import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { emailRegExp, validEmailDomainRegExp } from "@grainger/common/constants/validation.constants";
import { ValidationErrorType } from "../enums/validation-error-type";

//
export class CustomValidators {
    /**
     *
     * @param fieldToMatch
     * @param customErrorType optional custom error type, otherwise defaults to fieldMismatch
     * @param fieldNames optional field param to pass the names of the fields into error messages
     * @returns
     */
    static matchField(
        fieldToMatch: string,
        customErrorType?: ValidationErrorType,
        fieldNames?: { field1: string; field2: string }
    ): ValidatorFn {
        return (control: AbstractControl) => {
            if (!control || !control.value) {
                return null;
            }

            const parent = control.parent;

            if (!parent) {
                return null;
            }

            const matchingControl = parent.get(fieldToMatch);
            if (!matchingControl) {
                return null;
            }

            if (!matchingControl || !matchingControl.value) {
                return null;
            }

            if (control.value !== matchingControl.value) {
                if (customErrorType) {
                    console.log(customErrorType, " called in validator");
                    return { [customErrorType]: true };
                } else {
                    console.log("fieldMismatch called in validator");
                    return { fieldMismatch: { fieldNames } };
                }
            } else {
                return null;
            }
        };
    }

    static email(control: AbstractControl): ValidationErrors | null {
        const email: string = control.value;

        if (!emailRegExp.test(email)) {
            return {
                email: true
            };
        }

        return null;
    }

    static validEmailDomain(control: AbstractControl): ValidationErrors | null {
        const email: string = control.value;

        if (!email) {
            return null;
        }

        if (!validEmailDomainRegExp.test(email)) {
            return {
                invalidEmailDomain: true
            };
        }

        return null;
    }
}
