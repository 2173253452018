import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { IFacility } from "@grainger/common/definitions/facility";
import { IPillar } from "@grainger/common/definitions/pillar";
import { Observable, of, switchMap } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class FacilityService {
    private http = inject(HttpClient);

    constructor() {}
    // TODO: Add Caching
    getFacility(facilityId: string): Observable<IFacility | null> {
        return this.http.get<IFacility[] | undefined>(`${environment.baseUrl}/facilities/${facilityId}`).pipe(
            switchMap((facilities: IFacility[] | undefined) => {
                if (!facilities || facilities.length === 0) {
                    throw new Error("Facility not found");
                }

                const facilityData: IFacility = facilities[0];

                const sortedPillars = this.sortPillars(facilityData.pillars ?? []);

                return of({
                    ...facilityData,
                    pillars: sortedPillars,
                    id: facilityId
                });
            })
        );
    }

    private sortPillars(pillars: IPillar[]): IPillar[] {
        return pillars.sort((a, b) => {
            if (a.order < b.order) {
                return -1;
            }
            if (a.order > b.order) {
                return 1;
            }
            return 0;
        });
    }

    // TODO: GFE-26 Add method to get display departments
}
