<grainger-form-page-wrapper>
    <h1 class="form__heading">Login</h1>
    <form *ngIf="form" class="form" [formGroup]="form">
        <div class="c-form-fields">
            <grainger-form-control class="form-control">
                <input type="password" formControlName="password" placeholder="New Password" appInput="Password" />
            </grainger-form-control>
            <grainger-form-control class="form-control">
                <input
                    type="password"
                    formControlName="confirmPassword"
                    placeholder="Confirm Password"
                    appInput="Password Confirmation"
                />
            </grainger-form-control>
        </div>

        <div class="c-buttons">
            <div class="c-form-btn">
                <grainger-arrow-button
                    class="form-btn"
                    text="Update"
                    [handleInParent]="true"
                    (clicked)="updatePassword()"
                    [disabled]="isLoading"
                    arrowDirection="right"
                ></grainger-arrow-button>
            </div>

            <div class="form-change-link">
                <a
                    class="form-change-link__text"
                    [routerLink]="['/']"
                    (click)="trackClick('Clicked cancel', 'Reset Password Page')"
                    >Cancel</a
                >
            </div>
        </div>

        <p *ngIf="hasSubmissionError" class="submission-error">
            {{ submissionError }}
        </p>
    </form>
</grainger-form-page-wrapper>
