import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RouterModule } from "@angular/router";

@Component({
    selector: "grainger-mobile-department-header",
    standalone: true,
    imports: [CommonModule, RouterModule],
    templateUrl: "./mobile-department-header.component.html",
    styleUrl: "./mobile-department-header.component.scss"
})
export class MobileDepartmentHeaderComponent {
    @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

    @Input() text: string;
    @Input() route: string = "..";
}
