import { HttpErrorResponse, HttpInterceptorFn } from "@angular/common/http";
import { inject } from "@angular/core";
import { catchError } from "rxjs";
import { AuthService } from "../services/auth.service";

export const errorInterceptor: HttpInterceptorFn = (request, next) => {
    const authService = inject(AuthService);
    return next(request).pipe(
        catchError((err: HttpErrorResponse) => {
            if (!request.url.includes("auth/") && err.status === 401) {
                authService.logout();
                window.location.reload();
                throw new Error("Please log in to continue");
            } else {
                throw err;
            }
        })
    );
};
