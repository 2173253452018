<div class="c-sections">
    <ul class="selector-list">
        <ng-container
            *ngTemplateOutlet="
                sectionSelector;
                context: {
                    link: { canShow: canShowOverview, path: 'overview', text: 'overview' }
                }
            "
        ></ng-container>
        <ng-container
            *ngTemplateOutlet="
                sectionSelector;
                context: {
                    link: {
                        canShow: canShowChallenges,
                        path: 'challenges',
                        text: 'challenges'
                    }
                }
            "
        ></ng-container>
        <ng-container
            *ngTemplateOutlet="
                sectionSelector;
                context: {
                    link: {
                        canShow: canShowSolutions,
                        path: 'solutions',
                        text: 'solutions'
                    }
                }
            "
        ></ng-container>
        <ng-container
            *ngTemplateOutlet="
                sectionSelector;
                context: {
                    link: { canShow: canShowEmbeds, path: 'embeds', text: 'embeds' }
                }
            "
        ></ng-container>
        <ng-container
            *ngTemplateOutlet="
                sectionSelector;
                context: {
                    link: {
                        canShow: canShowResources,
                        path: 'resources',
                        text: 'resources'
                    }
                }
            "
        ></ng-container>
        <ng-container
            *ngTemplateOutlet="
                sectionSelector;
                context: {
                    link: { canShow: canShowInsights, path: 'insights', text: 'insights' }
                }
            "
        ></ng-container>
    </ul>
</div>

<ng-template #sectionSelector let-link="link">
    <a
        *ngIf="link.canShow"
        class="selector"
        [routerLink]="link.path"
        routerLinkActive="active"
        (click)="trackTabClick('Clicked tab - ' + link.text, 'Department Mobile Landing Page')"
    >
        <span class="selector__text">{{ link.text | uppercase }}</span>
        <span class="icon"><img src="assets/ui/icon-chevron-left.svg" alt="Go To Arrow" class="icon__img" /></span>
    </a>
</ng-template>
