<div class="tab-content" [ngClass]="{ 'can-edit': canEdit }" @fadeIn>
    <grainger-mobile-department-header
        text="Resources"
        (clicked)="trackMobileBackClick('resources')"
    ></grainger-mobile-department-header>

    <ng-container *ngIf="department">
        <div *ngIf="department.resources || canEdit" class="c-sections">
            <section class="section aligned-literature">
                <h2 class="section__heading section__heading--large">Aligned Literature</h2>

                <a
                    href="https://linkprotect.cudasvc.com/url?a=https%3a%2f%2ffortheones.okta.com%2foauth2%2fv1%2fauthorize%3fclient_id%3dokta.2b1959c8-bcc0-56eb-a589-cfcfb7422f26%26code_challenge%3dZdou0NQK3db-FBBPX-5CR_9WSDNdQYfUfcDTPArNSLA%26code_challenge_method%3dS256%26nonce%3dzGWKpcku6tziVUCFSeuokSk21F892E1jX1X6IVXZFXQopWL1OGuZw8St907qNHU8%26redirect_uri%3dhttps%253A%252F%252Ffortheones.okta.com%252Fenduser%252Fcallback%26response_type%3dcode%26state%3dwOLIZis1DPCKPjzlrTpLn7H0UiMtxhGAVv5b8pMKQpU57ZoPHaEqFAcRllsgivlx%26scope%3dopenid%2520profile%2520email%2520okta.users.read.self%2520okta.users.manage.self%2520okta.internal.enduser.read%2520okta.internal.enduser.manage%2520okta.enduser.dashboard.read%2520okta.enduser.dashboard.manage%2520okta.myAccount.sessions.manage&c=E,1,UevXeL96qjnP3NbgSTOYr7W_sSFVtriEuifm1iV7umm7fTx26BWW_v6vHjwz0SY5l6KpgNKIGqp84vy1I4v-tSXiJFz8NMZATsxGVjaBkBr54yKZ&typo=1"
                    target="_blank"
                    class="arrow-resource-link"
                    (click)="trackClick('Clicked aligned literature link', 'resources')"
                >
                    <span class="arrow-resource-link__icon"></span>
                    <span class="arrow-resource-link__text"> To download and order documents use Diamond FF </span>
                </a>
                <ul
                    *ngIf="department.resources?.alignedLiterature?.length || canEdit"
                    class="bullet-list resource-list"
                >
                    <li
                        *ngFor="let link of department.resources?.alignedLiterature; let i = index"
                        class="bullet-list__item resource-list__item"
                    >
                        <grainger-icon-list-item
                            [link]="link"
                            [fields]="{
                                name: 2
                            }"
                            (inlineSave)="updateDepartmentData('resources.alignedLiterature[' + i + ']', $event)"
                            (inlineDelete)="deleteDepartmentDataItem('resources.alignedLiterature', i)"
                        ></grainger-icon-list-item>
                    </li>
                    <li *ngIf="canEdit" class="resource-list__item">
                        <grainger-icon-list-item
                            [link]="{
                                name: '+ Add Aligned Literature'
                            }"
                            [fields]="{
                                name: 2
                            }"
                            (inlineSave)="
                                updateDepartmentData(
                                    'resources.alignedLiterature[' +
                                        (department.resources?.alignedLiterature?.length ?? 0) +
                                        ']',
                                    $event
                                )
                            "
                            [isNew]="true"
                        ></grainger-icon-list-item>
                    </li>
                </ul>
            </section>

            <ng-container
                *ngTemplateOutlet="
                    linkSection;
                    context: {
                        $implicit: {
                            title: 'Case Studies',
                            links: department.resources?.caseStudies,
                            headingClassModifier: '',
                            inlineEditPath: 'resources.caseStudies',
                            inlineAddName: '+ Add Case Study',
                            sectionClass: 'case-studies',
                            analyticsName: 'case studies'
                        }
                    }
                "
            ></ng-container>
            <ng-container
                *ngTemplateOutlet="
                    linkSection;
                    context: {
                        $implicit: {
                            title: 'Know How Articles',
                            links: department.resources?.knowHowArticles,
                            headingClassModifier: '',
                            inlineEditPath: 'resources.knowHowArticles',
                            inlineAddName: '+ Add Know How Article',
                            sectionClass: 'know-how',
                            analyticsName: 'know how article'
                        }
                    }
                "
            ></ng-container>
            <ng-container
                *ngTemplateOutlet="
                    linkSection;
                    context: {
                        $implicit: {
                            title: 'GTES & LinkedIn Learnings',
                            links: department.resources?.learnings,
                            headingClassModifier: '',
                            inlineEditPath: 'resources.learnings',
                            inlineAddName: '+ Add GTES & LinkedIn Learning',
                            sectionClass: 'learnings',
                            analyticsName: 'GTES & linkedin learnings'
                        }
                    }
                "
            ></ng-container>

            <ng-container
                *ngTemplateOutlet="
                    linkSection;
                    context: {
                        $implicit: {
                            title: 'YouTube Links',
                            links: department.resources?.youtubeVideos,
                            headingClassModifier: '',
                            inlineEditPath: 'resources.youtubeVideos',
                            inlineAddName: '+ Add YouTube Link',
                            sectionClass: 'youtube',
                            analyticsName: 'youtube'
                        }
                    }
                "
            ></ng-container>
        </div>
    </ng-container>

    <div class="logo">
        <img src="assets/images/logo.svg" alt="Grainger Logo" class="logo__img" />
    </div>
</div>

<ng-template #linkSection let-section>
    <section
        *ngIf="(section.links && section.links?.length) || canEdit"
        class="section {{ section?.sectionClass ?? '' }}"
    >
        <h2
            class="section__heading{{
                section.headingClassModifier ? ' section__heading--' + section.headingClassModifier : ''
            }}"
        >
            {{ section.title }}
        </h2>

        <ul class="link-list resource-list">
            <li *ngFor="let link of section.links; let i = index" class="link-list__item resource-list__item">
                <grainger-icon-list-item
                    [link]="link"
                    [fields]="{
                        name: 2,
                        url: 2
                    }"
                    (clicked)="
                        link.url &&
                            trackClick(
                                'Clicked ' + section.analyticsName + ' link - ' + (link.name || link.url),
                                'resources'
                            )
                    "
                    (inlineSave)="updateDepartmentData(section.inlineEditPath + '[' + i + ']', $event)"
                    (inlineDelete)="deleteDepartmentDataItem(section.inlineEditPath, i)"
                ></grainger-icon-list-item>
            </li>
            <li *ngIf="canEdit" class="resource-list__item">
                <grainger-icon-list-item
                    [link]="{
                        name: section.inlineAddName
                    }"
                    [fields]="{
                        name: 2,
                        url: 2
                    }"
                    (inlineSave)="
                        updateDepartmentData(section.inlineEditPath + '[' + (section.links?.length ?? 0) + ']', $event)
                    "
                    [isNew]="true"
                ></grainger-icon-list-item>
            </li>
        </ul>
    </section>
</ng-template>
