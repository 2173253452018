import { Injectable } from "@angular/core";

@Injectable()
export class StorageService {
    public getItem(key: string): any {
        try {
            const item = localStorage.getItem(key) || null;

            if (item === null) {
                return null;
            }

            const parsedData = JSON.parse(localStorage.getItem(key) || "");
            return parsedData;
        } catch {
            console.error("Error getting item from storage");
            return null;
        }
    }

    public setItem(key: string, value: any): void {
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch {
            console.error("Error saving item to storage");
        }
    }

    public removeItem(key: string): void {
        if (localStorage.getItem(key)) {
            localStorage.removeItem(key);
        }
    }
}
