import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
    selector: "grainger-form-page-wrapper",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./form-page-wrapper.component.html",
    styleUrl: "./form-page-wrapper.component.scss"
})
export class FormPageWrapperComponent {}
