import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class UtilityService {
    constructor() {}

    convertToPercentOfContainer(pixels: number, containerWidth: number): number {
        containerWidth = containerWidth; // Base screen width for your design
        return (pixels / containerWidth) * 100;
    }
}
