import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { IDepartment } from "@grainger/common/definitions/department";
import { Observable, map, of } from "rxjs";
import { DepartmentService } from "../services/department.service";

export class DepartmentResolver {
    static getDepartment: ResolveFn<Observable<IDepartment | null> | IDepartment | null> = (
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) => {
        const departmentService = inject(DepartmentService);

        const id = route.params["id"] ?? null;

        if (!id) {
            return of(null);
        }

        return departmentService.getDepartment(id).pipe(
            map((dept) => {
                return dept;
            })
        );
    };
}
