import { Directive, HostBinding, Input, Optional, Self } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
    selector: "[appInput]",
    standalone: true
})
export class InputDirective {
    // Set the label information of the field for error messages
    @Input() appInput: string | null;

    constructor(@Optional() @Self() public ngControl: NgControl) {}

    public get control() {
        return this.ngControl?.control;
    }

    public get errors() {
        return this.control?.errors || {};
    }

    public get fieldName() {
        return this.appInput || "This field";
    }

    @HostBinding("attr.id") public get id(): string {
        return this.ngControl.name + "" || "";
    }
}
