import { CommonModule } from "@angular/common";
import { Component, OnInit, inject } from "@angular/core";
import { IProduct } from "@grainger/common/definitions/product";
import { ISupplier } from "@grainger/common/definitions/supplier";
import { NgSelectModule } from "@ng-select/ng-select";
import { Observable, Subject, catchError, concat, distinctUntilChanged, map, of, switchMap, tap } from "rxjs";
import { AccordionItemComponent } from "../components/accordion-item/accordion-item.component";
import { ArrowButtonComponent } from "../components/arrow-button/arrow-button.component";
import { BaseTabComponent } from "../components/for-extension-only/base-tab/base-tab.component";
import { IconListItemComponent } from "../components/icon-list-item/icon-list-item.component";
import { MobileDepartmentHeaderComponent } from "../components/mobile-department-header/mobile-department-header.component";
import { SupplierAccordionComponent } from "../components/supplier-accordion/supplier-accordion.component";
import { CustomAnimations } from "../core/animations/animations";
import { InlineInputDirective } from "../core/directives/inline-input.directive";
import { ArrayToColumnsPipe } from "../core/pipes/array-to-columns.pipe";
import { CapitalizeFirstLetterPipe } from "../core/pipes/capitalize-first-letter.pipe";
import { ProductsService } from "../core/services/products.service";
import { SupplierService } from "../core/services/supplier.service";

@Component({
    selector: "grainger-solutions",
    standalone: true,
    imports: [
        CommonModule,
        IconListItemComponent,
        SupplierAccordionComponent,
        ArrayToColumnsPipe,
        MobileDepartmentHeaderComponent,
        CapitalizeFirstLetterPipe,
        ArrowButtonComponent,
        AccordionItemComponent,
        InlineInputDirective,
        NgSelectModule
    ],
    templateUrl: "./solutions.component.html",
    styleUrl: "./solutions.component.scss",
    animations: [CustomAnimations.fadeIn("0.3s")]
})
export class SolutionsComponent extends BaseTabComponent implements OnInit {
    public products$: Observable<IProduct[]>;
    public productsLoading = false;
    public productsInput$ = new Subject<string>();
    public suppliers$: Observable<ISupplier[]>;
    public suppliersLoading = false;
    public suppliersInput$ = new Subject<string>();

    private productsService = inject(ProductsService);
    private supploersService = inject(SupplierService);

    ngOnInit(): void {
        this.products$ = this.initProductsSearch();
        this.suppliers$ = this.initSuppliersSearch();
    }

    public trackSupplierBy(index: number, supplier: ISupplier): string {
        return supplier.name;
    }

    public trackSuppliersBy(supplier: ISupplier): string {
        return supplier.id!;
    }

    public trackProductsBy(product: IProduct): string {
        return product.id!;
    }

    private initProductsSearch() {
        return concat(
            of([]), // default items
            this.productsInput$.pipe(
                distinctUntilChanged(),
                tap(() => (this.productsLoading = true)),
                switchMap((term) =>
                    this.productsService.getProducts(term).pipe(
                        map((products) =>
                            products.filter(
                                (product) =>
                                    (this.department?.products ?? []).find((p) => p.id === product.id) === undefined
                            )
                        ),
                        catchError(() => of([])), // empty list on error
                        tap(() => (this.productsLoading = false))
                    )
                )
            )
        );
    }

    private initSuppliersSearch() {
        return concat(
            of([]), // default items
            this.suppliersInput$.pipe(
                distinctUntilChanged(),
                tap(() => (this.suppliersLoading = true)),
                switchMap((term) =>
                    this.supploersService.searchSuppliers({ search: term }).pipe(
                        map((suppliers) =>
                            (suppliers?.data ?? []).filter(
                                (supplier) =>
                                    (this.department?.suppliers ?? []).find((s) => s.id === supplier.id) === undefined
                            )
                        ),
                        catchError(() => of([])), // empty list on error
                        tap(() => (this.suppliersLoading = false))
                    )
                )
            )
        );
    }
}
