import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { map } from "rxjs";
import { Breakpoint } from "../enums/breakpoint.enum";
import { WindowSizeService } from "../services/window-size.service";

export class MobileDepartmentLandingGuard {
    static canActivate: CanActivateFn = (route, state) => {
        const windowSizeService = inject(WindowSizeService);
        const router = inject(Router);

        return windowSizeService.getWidth().pipe(
            map((width: number) => {
                if (width >= Breakpoint.Desktop) {
                    router.navigate([state.url, "overview"]);
                    return false;
                } else {
                    return true;
                }
            })
        );
    };
}
