import { CommonModule } from "@angular/common";
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
    ViewChild,
    inject
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { Router, RouterModule } from "@angular/router";
import { ILinkableResource } from "@grainger/common/definitions/common";
import { tap } from "rxjs";
import { EditService } from "../../core/services/edit.service";
import { UrlHelpers } from "../../core/utils/url.util";

@Component({
    selector: "grainger-icon-list-item",
    standalone: true,
    imports: [CommonModule, RouterModule, ReactiveFormsModule],
    templateUrl: "./icon-list-item.component.html",
    styleUrl: "./icon-list-item.component.scss"
})
export class IconListItemComponent implements AfterViewInit {
    @Output() public clicked: EventEmitter<void> = new EventEmitter<void>();
    @Output() public inlineSave: EventEmitter<ILinkableResource> = new EventEmitter<ILinkableResource>();
    @Output() public inlineDelete: EventEmitter<void> = new EventEmitter<void>();

    @Input() link!: ILinkableResource;
    @Input() activeRouteExactMatch: boolean = true;

    /**
     * Pass true or number of rows to display in the textarea
     */
    @Input() fields: {
        name?: boolean | number;
        description?: boolean | number;
        url?: boolean | number;
    } = {
        name: true,
        description: true,
        url: true
    };
    @Input()
    @HostBinding("class.placeholder")
    @Input()
    public isNew = false;

    @Input()
    public disableEdit = false;

    @Input()
    public disableDelete = false;
    @Input() listStyleType = "&bull;";

    public canEdit: boolean = false;

    public isExternal: boolean = false;

    public isEditing: boolean = false;
    public inlineForm: FormGroup | null = null;

    @ViewChild("nameInput") nameInput: ElementRef<HTMLInputElement>;

    private elementRef = inject(ElementRef);
    private fb = inject(FormBuilder);
    private changeDetector = inject(ChangeDetectorRef);
    private router = inject(Router);
    private destroyRef = inject(DestroyRef);
    private editService = inject(EditService);

    ngAfterViewInit(): void {
        if (this.link) {
            if (this.link.url) {
                this.isExternal = UrlHelpers.isExternalLink(this.link.url);
                this.changeDetector.detectChanges();
            }
        }

        this.editService.isEditing$
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                tap((canEdit) => {
                    this.canEdit = canEdit && !this.disableEdit;
                    if (!canEdit) {
                        if (this.isEditing) {
                            this.toggleInlineEdit();
                        }
                    }
                })
            )
            .subscribe();
    }

    goToInternalLink(link: string | undefined) {
        this.router.navigate([link]);
    }

    public toggleInlineEdit(event?: Event): void {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.isEditing = !this.isEditing;
        if (this.isEditing) {
            this.inlineForm = this.initInlineForm(this.link);
            this.changeDetector.detectChanges();
            this.nameInput.nativeElement.focus();
        } else {
            this.inlineForm = null;
        }
    }

    private initInlineForm(link?: ILinkableResource | null) {
        return this.fb.group({
            id: [link?.id ?? undefined],
            name: [this.isNew ? "" : link?.name ?? ""],
            description: [link?.description ?? ""],
            url: [link?.url ?? ""]
        });
    }

    public saveInlineInput(): void {
        this.inlineSave.emit(this.inlineForm!.value);
        this.isEditing = false;
    }

    public deleteItem(event: Event) {
        event.stopPropagation();
        event.preventDefault();
        this.inlineDelete.emit();
    }

    @HostListener("document:click", ["$event"])
    public onOutsideClick(event: Event) {
        const target = event.target as HTMLElement;
        if (this.isEditing && !this.elementRef.nativeElement.contains(target)) {
            this.toggleInlineEdit();
        }
    }
}
