import { CommonModule } from "@angular/common";
import { Component, OnInit, inject } from "@angular/core";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { catchError, throwError } from "rxjs";
import { ArrowButtonComponent } from "../../components/arrow-button/arrow-button.component";
import { BaseAuthFormComponent } from "../../components/base-auth-form/base-auth-form.component";
import { FormControlComponent } from "../../components/form-control/form-control.component";
import { FormPageWrapperComponent } from "../../components/form-page-wrapper/form-page-wrapper.component";
import { InputDirective } from "../../core/directives/input.directive";
import { ValidationErrorType } from "../../core/enums/validation-error-type";
import { CustomValidators } from "../../core/validators/custom-validators.validator";

@Component({
    selector: "grainger-update-password",
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        ArrowButtonComponent,
        ReactiveFormsModule,
        FormControlComponent,
        InputDirective,
        FormPageWrapperComponent
    ],
    templateUrl: "./update-password.component.html",
    styleUrl: "./update-password.component.scss"
})
export class UpdatePasswordComponent extends BaseAuthFormComponent implements OnInit {
    private route = inject(ActivatedRoute);
    private router = inject(Router);

    private email: string | null = null;
    private resetToken: string | null = null;

    override ngOnInit(): void {
        super.ngOnInit();

        this.route.queryParams.subscribe((params) => {
            const { email, token } = params;
            if (!email || !token) {
                throw new Error("Unable to reset password for unknown user");
            }

            this.email = email;
            this.resetToken = token;
        });

        this.analyticsService.trackPageView({ pageTitle: "Reset Password" });
    }

    initForm() {
        return this.fb.group({
            password: this.fb.control(null, [Validators.required, Validators.minLength(8)]),
            confirmPassword: this.fb.control(null, [
                Validators.required,
                CustomValidators.matchField("password", ValidationErrorType.PasswordMismatch)
            ])
        });
    }

    public updatePassword() {
        if (this.isLoading) {
            return;
        }

        this.trackClick("Clicked update", "Reset Password Page");

        this.hasSubmissionError = false;

        if (this.form.invalid) {
            this.form.markAllAsTouched();
            this.form.updateValueAndValidity();
            return;
        }

        this.authService
            .updatePassword({
                email: this.email || "",
                resetToken: this.resetToken || "",
                newPassword: this.password.value
            })
            .pipe(
                catchError((err) => {
                    this.hasSubmissionError = true;
                    this.submissionError = err.message;
                    return throwError(() => "Error updating password");
                })
            )
            .subscribe(() => {
                this.router.navigate(["/"]);
            });
    }

    private get password() {
        return this.form.get("password") as FormControl;
    }

    private get confirmPassword() {
        return this.form.get("confirmPassword") as FormControl;
    }
}
