import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BaseDepartmentComponent } from "../components/for-extension-only/base-department/base-department.component";
import { SubpageHeaderComponent } from "../components/subpage-header/subpage-header.component";
import { InlineInputDirective } from "../core/directives/inline-input.directive";

@Component({
    selector: "grainger-department",
    standalone: true,
    imports: [CommonModule, RouterModule, SubpageHeaderComponent, InlineInputDirective],
    templateUrl: "./department.component.html",
    styleUrl: "./department.component.scss"
})
export class DepartmentComponent extends BaseDepartmentComponent implements AfterViewInit {
    @ViewChild("tabs") tabs!: ElementRef<HTMLDivElement>;

    public tabScrolled: boolean = false;

    @HostListener("window:scroll", ["$event"])
    onWindowScroll(event: Event): void {
        this.handleTabScroll();
    }

    public handleTabScroll(): void {
        const el = this.tabs.nativeElement;

        const rect = el.getBoundingClientRect();
        const styleTop = parseInt(window.getComputedStyle(el).top, 10);

        const threshold = styleTop;

        this.tabScrolled = rect.top <= threshold || rect.top - threshold <= 1;
    }
}
