import { CommonModule } from "@angular/common";
import {
    AfterViewInit,
    Component,
    ContentChildren,
    DestroyRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    QueryList,
    SimpleChanges,
    ViewEncapsulation,
    inject
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ISupplier } from "@grainger/common/definitions/supplier";
import { map, merge, startWith, switchMap, tap } from "rxjs";
import { AccordionControl } from "../../core/interfaces/accordion-control.interface";
import { CapitalizeFirstLetterPipe } from "../../core/pipes/capitalize-first-letter.pipe";
import { AccordionItemComponent } from "../accordion-item/accordion-item.component";
import { ArrowButtonComponent } from "../arrow-button/arrow-button.component";
import { IconListItemComponent } from "../icon-list-item/icon-list-item.component";

@Component({
    selector: "grainger-supplier-accordion",
    standalone: true,
    imports: [
        CommonModule,
        IconListItemComponent,
        ArrowButtonComponent,
        AccordionItemComponent,
        CapitalizeFirstLetterPipe
    ],
    templateUrl: "./supplier-accordion.component.html",
    styleUrl: "./supplier-accordion.component.scss",
    encapsulation: ViewEncapsulation.None
})
export class SupplierAccordionComponent implements AccordionControl, AfterViewInit, OnChanges {
    @Output() analyticsEvent: EventEmitter<string> = new EventEmitter<string>();

    @Input() suppliers: ISupplier[] = [];
    @Input() heading: string | null = "Key Suppliers";

    public currentAccordionIndex: number | null;

    @ContentChildren(AccordionItemComponent, {
        descendants: true
    })
    accordionItems: QueryList<AccordionItemComponent>;
    protected destroy$ = inject(DestroyRef);

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["suppliers"]) {
            this.currentAccordionIndex = null;
        }
    }

    ngAfterViewInit(): void {
        this.getAccordianSelectionEvents();
    }

    /**
     * Listens for all child accordion item click events
     * Update the current index and accordian state based on the click event
     */
    private getAccordianSelectionEvents() {
        this.accordionItems.changes
            .pipe(
                takeUntilDestroyed(this.destroy$),
                startWith(this.accordionItems),
                switchMap((list: QueryList<AccordionItemComponent>) => {
                    const items = list.toArray();
                    return merge(...items.map((item, index) => item.selectedItem.pipe(map(() => index))));
                }),
                tap((index) => {
                    this.trackSelection(index);
                    this.updateAccordionIndex(index);
                })
            )
            .subscribe();
    }

    private trackSelection(index: number): void {
        const supplier = this.suppliers[index];
        this.analyticsEvent.emit(
            "Content " + (index === this.currentAccordionIndex ? "collapsed" : "expanded") + " - " + supplier.name
        );
    }

    public updateAccordionIndex(index: number): void {
        this.currentAccordionIndex = this.currentAccordionIndex === index ? null : index;
        this.accordionItems.forEach((item, i) => {
            item.isOpen = this.currentAccordionIndex === i && index !== null;
        });
    }
}
