import { CommonModule } from "@angular/common";
import { Component, DestroyRef, OnInit, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { IUser } from "@grainger/common/definitions/user";
import { InputDirective } from "../../core/directives/input.directive";
import { AnalyticsService } from "../../core/services/analytics.service";
import { AuthService } from "../../core/services/auth.service";
import { LoadingService } from "../../core/services/loading.service";
import { ArrowButtonComponent } from "../arrow-button/arrow-button.component";
import { FormControlComponent } from "../form-control/form-control.component";
import { FormPageWrapperComponent } from "../form-page-wrapper/form-page-wrapper.component";

@Component({
    selector: "grainger-base-auth-form",
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        ArrowButtonComponent,
        ReactiveFormsModule,
        FormControlComponent,
        InputDirective,
        FormPageWrapperComponent
    ],
    templateUrl: "./base-auth-form.component.html",
    styleUrl: "./base-auth-form.component.scss"
})
export abstract class BaseAuthFormComponent implements OnInit {
    protected fb = inject(FormBuilder);
    protected authService = inject(AuthService);
    protected loadingService = inject(LoadingService);
    protected destroy$ = inject(DestroyRef);
    protected analyticsService = inject(AnalyticsService);

    public form!: FormGroup;

    public isLoading: boolean = false;
    public hasSubmissionError: boolean = false;
    public submissionError: string = "There was an error, please try again in a few moments.";

    ngOnInit(): void {
        this.loadingService
            .getLoadingStatus()
            .pipe(takeUntilDestroyed(this.destroy$))
            .subscribe((isLoading) => {
                this.isLoading = isLoading;
            });

        this.form = this.initForm();
    }

    protected abstract initForm(user?: IUser): FormGroup;

    public trackClick(label: string, location: string): void {
        this.analyticsService.trackInteraction({
            action: "click",
            location: location,
            category: "button",
            label
        });
    }
}
