import { IsEnum } from "class-validator";
import { DISTRICTS } from "../constants/district.constants";
import { SALES_REGIONS_AND_FUNCTIONS } from "../constants/sales-region-function.constants";

export class UpdateUserProfileDto {
    @IsEnum(SALES_REGIONS_AND_FUNCTIONS)
    readonly regionOrFunction: (typeof SALES_REGIONS_AND_FUNCTIONS)[number] | null;

    @IsEnum(DISTRICTS)
    readonly district: (typeof DISTRICTS)[number] | null;
}
