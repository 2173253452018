import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { BaseTabComponent } from "../components/for-extension-only/base-tab/base-tab.component";
import { IconListItemComponent } from "../components/icon-list-item/icon-list-item.component";
import { MobileDepartmentHeaderComponent } from "../components/mobile-department-header/mobile-department-header.component";
import { CustomAnimations } from "../core/animations/animations";
import { InlineInputDirective } from "../core/directives/inline-input.directive";

@Component({
    selector: "grainger-insights",
    standalone: true,
    imports: [
        CommonModule,
        IconListItemComponent,
        MobileDepartmentHeaderComponent,
        MobileDepartmentHeaderComponent,
        InlineInputDirective
    ],
    templateUrl: "./insights.component.html",
    styleUrl: "./insights.component.scss",
    animations: [CustomAnimations.fadeIn("0.3s")]
})
export class InsightsComponent extends BaseTabComponent {}
