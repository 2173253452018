<div class="tab-content" [ngClass]="{ 'can-edit': canEdit }" @fadeIn>
    <grainger-mobile-department-header
        text="Embeds"
        (clicked)="trackMobileBackClick('embeds')"
    ></grainger-mobile-department-header>
    <ng-container *ngIf="department">
        <ng-container *ngIf="department.embeds?.length || canEdit">
            <section class="section embeds">
                <h2 class="embeds__heading section__heading section__heading--large">Embeds</h2>

                <div class="accordion">
                    <ng-container *ngFor="let embed of department?.embeds; let i = index">
                        <ng-container *ngTemplateOutlet="embedAccordian; context: { embed, index: i }"></ng-container>
                    </ng-container>
                    <ng-template #embedAccordian let-embed="embed" let-i="index">
                        <grainger-accordion-item
                            *ngIf="(embed?.name && (embed?.urls?.length || embed?.description)) || canEdit"
                            class="accordion-item"
                            [isOpen]="currentAccordionIndex === i && currentAccordionIndex !== null"
                            (selectedItem)="
                                !!embed &&
                                    trackClick(
                                        'Content ' +
                                            (i === currentAccordionIndex ? 'collapsed' : 'expanded') +
                                            ' - ' +
                                            embed.name,
                                        'embeds'
                                    );
                                updateAccordionIndex(i)
                            "
                        >
                            <span
                                *ngIf="embed?.name || canEdit"
                                accordion-heading
                                (appInlineInput)="updateDepartmentData('embeds[' + i + '].name', $event)"
                                (inlineDelete)="deleteDepartmentDataItem('embeds', i)"
                                [rows]="2"
                                [isNew]="canEdit && !embed?.name"
                                [alwaysShowButtons]="true"
                            >
                                {{ embed?.name || (canEdit ? "+ Add Name" : "") }}
                            </span>

                            <ng-container accordion-content>
                                <p
                                    *ngIf="embed?.description || canEdit"
                                    class="accordion-content__copy"
                                    (appInlineInput)="updateDepartmentData('embeds[' + i + '].description', $event)"
                                    (inlineDelete)="updateDepartmentData('embeds[' + i + '].description', null)"
                                    [rows]="2"
                                    [isNew]="canEdit && !embed?.description"
                                >
                                    {{
                                        embed?.description || (canEdit ? "+ Add Description" : "")
                                            | capitalizeFirstLetter
                                    }}
                                </p>

                                <ul *ngIf="embed?.urls?.length || canEdit" class="link-list accordion-content__links">
                                    <grainger-icon-list-item
                                        *ngFor="let resource of embed?.urls; let j = index"
                                        class="link-list__item accordion-content__link-item"
                                        [link]="{
                                            name: resource.name || resource.url || '',
                                            url: resource.url
                                        }"
                                        [fields]="{
                                            name: 2,
                                            url: 2
                                        }"
                                        (inlineSave)="updateDepartmentData('embeds[' + i + '].urls[' + j + ']', $event)"
                                        (inlineDelete)="deleteDepartmentDataItem('embeds[' + i + '].urls', j)"
                                        (clicked)="
                                            !!embed &&
                                                trackClick(
                                                    'Clicked embed link - ' +
                                                        embed.name +
                                                        ' - ' +
                                                        (resource.name || resource.url || ''),
                                                    'embeds'
                                                )
                                        "
                                    ></grainger-icon-list-item>
                                    <grainger-icon-list-item
                                        *ngIf="canEdit"
                                        class="accordion-content__link-item"
                                        [link]="{
                                            name: '+ Add Link'
                                        }"
                                        [fields]="{
                                            name: 2,
                                            url: 2
                                        }"
                                        [isNew]="true"
                                        (inlineSave)="
                                            updateDepartmentData(
                                                'embeds[' +
                                                    i +
                                                    '].urls[' +
                                                    ((department.embeds ?? [])[i].urls ?? []).length +
                                                    ']',
                                                $event
                                            )
                                        "
                                    ></grainger-icon-list-item>
                                </ul>
                            </ng-container>
                        </grainger-accordion-item>
                    </ng-template>
                    <div
                        class="placeholder add-item"
                        *ngIf="canEdit"
                        (click)="
                            updateDepartmentData('embeds[' + (department.embeds?.length ?? 0) + ']', {
                                name: '',
                                description: '',
                                urls: []
                            })
                        "
                    >
                        + Add Embed
                    </div>
                    <!-- <ng-container *ngIf="canEdit">
                        <ng-container
                            *ngTemplateOutlet="embedAccordian; context: { index: department.embeds?.length ?? 0 }"
                        ></ng-container>
                    </ng-container> -->
                </div>
            </section>

            <div class="logo">
                <img src="assets/images/logo.svg" alt="Grainger Logo" class="logo__img" />
            </div>
        </ng-container>
    </ng-container>
</div>
