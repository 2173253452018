import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";

export class ResetPasswordGuard {
    static canActivate: CanActivateFn = (route, state) => {
        if (route.queryParamMap.get("token") && route.queryParamMap.get("email")) {
            return true;
        }

        inject(Router).navigate([""]);
        return false;
    };
}
