import { CommonModule } from "@angular/common";
import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ClickOutsideDirective } from "../../core/directives/click-outside.directive";

@Component({
    selector: "grainger-select",
    standalone: true,
    imports: [CommonModule, ClickOutsideDirective],
    templateUrl: "./select.component.html",
    styleUrl: "./select.component.scss",
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: SelectComponent, multi: true }]
})
export class SelectComponent implements ControlValueAccessor {
    @Input() options: readonly (string | number | boolean | null)[] = [];

    @Input() label: string = "";

    @ViewChild("dropdownContainer")
    dropdownContainer: ElementRef<HTMLDivElement>;

    private _value: string | number | boolean | null | undefined = null;

    private _onChange(_: any) {}
    private _onTouched() {}

    public isExpanded: boolean = false;

    constructor() {}

    public selectionChanged(selection: string | number | boolean | null | undefined): void {
        this._value = selection;
        this._onChange(selection);
        this._onTouched();

        this.isExpanded = false;
    }

    writeValue(value: any): void {
        this._value = value;
    }

    registerOnChange(fn: (_: any) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this._onTouched = fn;
    }

    public toggleAccordion(): void {
        this.isExpanded = !this.isExpanded;

        if (!this.isExpanded) {
            this._onTouched();
        } else {
            this.dropdownContainer.nativeElement.scrollTop = 0;
        }
    }

    public closeDropdown(event: any): void {
        if (!this.isExpanded) {
            return;
        }

        this.isExpanded = false;

        this._onTouched();
    }

    public get value(): string | number | boolean | null | undefined {
        return this._value || null;
    }

    public onKeyDown(event: KeyboardEvent, option: string | number | boolean | null | undefined): void {
        if (event.key === "Enter") {
            this.isExpanded = !this.isExpanded;

            this.selectionChanged(option);
        }
    }
}
