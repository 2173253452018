import { CommonModule } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router, RouterModule } from "@angular/router";
import { DISTRICTS } from "@grainger/common/constants/district.constants";
import { SALES_REGIONS_AND_FUNCTIONS } from "@grainger/common/constants/sales-region-function.constants";
import { catchError } from "rxjs";
import { ArrowButtonComponent } from "../../components/arrow-button/arrow-button.component";
import { BaseAuthFormComponent } from "../../components/base-auth-form/base-auth-form.component";
import { FormControlComponent } from "../../components/form-control/form-control.component";
import { FormPageWrapperComponent } from "../../components/form-page-wrapper/form-page-wrapper.component";
import { SelectComponent } from "../../components/select/select.component";
import { InputDirective } from "../../core/directives/input.directive";
import { ValidationErrorType } from "../../core/enums/validation-error-type";
import { CustomValidators } from "../../core/validators/custom-validators.validator";

@Component({
    selector: "grainger-register",
    standalone: true,
    imports: [
        RouterModule,
        CommonModule,
        ReactiveFormsModule,
        FormPageWrapperComponent,
        FormControlComponent,
        ArrowButtonComponent,
        InputDirective,
        SelectComponent
    ],
    templateUrl: "./register.component.html",
    styleUrl: "./register.component.scss"
})
export class RegisterComponent extends BaseAuthFormComponent implements OnInit {
    private router = inject(Router);

    public readonly regionOrFunctionOptions = SALES_REGIONS_AND_FUNCTIONS;

    public readonly districtOptions = DISTRICTS;

    override ngOnInit(): void {
        super.ngOnInit();

        this.initChangeListeners();

        this.analyticsService.trackPageView({ pageTitle: "Register" });
    }

    protected initForm() {
        return this.fb.group({
            email: this.fb.control(null, [
                Validators.required,
                CustomValidators.email,
                CustomValidators.validEmailDomain
            ]),
            password: this.fb.control(null, [Validators.required, Validators.minLength(8)]),
            confirmPassword: this.fb.control(null, [Validators.required]),
            regionOrFunction: this.fb.control(null, [Validators.required]),
            district: this.fb.control(null, [Validators.required])
        });
    }

    private initChangeListeners() {
        this.password.valueChanges.pipe(takeUntilDestroyed(this.destroy$)).subscribe((value) => {
            if (value) {
                this.confirmPassword.setValidators([
                    Validators.required,
                    CustomValidators.matchField("password", ValidationErrorType.PasswordMismatch)
                ]);
            } else {
                this.confirmPassword.setValidators([Validators.required]);
            }
        });
    }

    public register(): void {
        this.trackClick("Clicked register", "Register Page");
        this.hasSubmissionError = false;

        if (this.form.invalid) {
            this.form.markAllAsTouched();
            this.form.updateValueAndValidity();

            return;
        }

        const { email, password, regionOrFunction, district } = this.form.value;

        this.authService
            .register({
                email,
                password,
                regionOrFunction,
                district
            })
            .pipe(
                catchError((err) => {
                    this.hasSubmissionError = true;
                    this.submissionError = err.message;
                    throw Error("Error registering: " + err.message);
                })
            )
            .subscribe(() => {
                this.router.navigate(["/"]);
            });
    }

    private get email(): FormControl {
        return this.form.get("email") as FormControl;
    }

    private get password(): FormControl {
        return this.form.get("password") as FormControl;
    }

    private get confirmPassword(): FormControl {
        return this.form.get("confirmPassword") as FormControl;
    }
}
