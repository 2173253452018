import { Component, inject } from "@angular/core";
import { CustomAnimations } from "../../core/animations/animations";
import { EditService } from "../../core/services/edit.service";

@Component({
    selector: "grainger-edit-mode-popover",
    templateUrl: "./edit-mode-popover.component.html",
    styleUrls: ["./edit-mode-popover.component.scss"],
    standalone: true,
    animations: [CustomAnimations.fadeIn("0.3s")]
})
export class EditModePopoverComponent {
    private editService = inject(EditService);

    close() {
        this.editService.toggleEditModePopover();
    }
}
