<div *ngIf="department" class="tab-content" [ngClass]="{ 'can-edit': canEdit }" @fadeIn>
    <grainger-mobile-department-header
        text="Challenges"
        (clicked)="trackMobileBackClick('challenges')"
    ></grainger-mobile-department-header>

    <section class="section top-issues" *ngIf="department.challenges || canEdit">
        <h2 class="section__heading section__heading--large top-issues__heading">Top Issues</h2>
        <div class="section__content">
            <div
                *ngIf="department.facility!.challenges?.topIssuesDescription || canEdit"
                class="top-issues__description"
                [ngClass]="{ placeholder: !department.facility!.challenges?.topIssuesDescription && canEdit }"
                (appInlineInput)="updateDepartmentData('facility.challenges.topIssuesDescription', $event)"
                (inlineDelete)="deleteDepartmentDataItem('facility.challenges.topIssuesDescription', 0)"
                [rows]="4"
                [isNew]="!department.facility!.challenges?.topIssuesDescription && canEdit"
            >
                {{
                    department.facility!.challenges?.topIssuesDescription ||
                        (canEdit ? "+ Add Facility Top Issues Description" : "")
                }}
            </div>

            <ul
                *ngIf="department.facility!.challenges?.issues?.length || canEdit"
                class="bullet-list dash-list facility-issues-list top-issues__list"
            >
                <li
                    *ngFor="let issue of department.facility!.challenges?.issues; let i = index"
                    class="facility-issues-list__item"
                >
                    <grainger-icon-list-item
                        [link]="{ name: issue | capitalizeFirstLetter }"
                        [fields]="{
                            name: 2
                        }"
                        listStyleType="- "
                        (inlineSave)="updateDepartmentData('facility.challenges.issues[' + i + ']', $event.name)"
                        (inlineDelete)="deleteDepartmentDataItem('facility.challenges.issues', i)"
                    ></grainger-icon-list-item>
                </li>
                <li *ngIf="canEdit" class="facility-issues-list__item">
                    <grainger-icon-list-item
                        [link]="{ name: '+ Add Facility Issue' }"
                        [fields]="{
                            name: 2
                        }"
                        (inlineSave)="
                            updateDepartmentData(
                                'facility.challenges.issues[' +
                                    (department.facility!.challenges?.issues?.length ?? 0) +
                                    ']',
                                $event.name
                            )
                        "
                        [isNew]="true"
                    ></grainger-icon-list-item>
                </li>
            </ul>

            <ng-container *ngIf="department.challenges?.examples?.length || canEdit">
                <p class="top-issues__description">Examples include:</p>
                <ul class="bullet-list top-issues__list">
                    <li
                        *ngFor="let example of department.challenges?.examples; let i = index"
                        class="bullet-list__item top-issues__list-item"
                    >
                        <grainger-icon-list-item
                            [link]="{ name: example | capitalizeFirstLetter }"
                            [fields]="{
                                name: 4
                            }"
                            (inlineSave)="updateDepartmentData('challenges.examples[' + i + ']', $event.name)"
                            (inlineDelete)="deleteDepartmentDataItem('challenges.examples', i)"
                        ></grainger-icon-list-item>
                    </li>
                    <li *ngIf="canEdit" class="bullet-list__item top-issues__list-item">
                        <grainger-icon-list-item
                            [link]="{ name: '+ Add Department Example' }"
                            [fields]="{
                                name: 4
                            }"
                            (inlineSave)="
                                updateDepartmentData(
                                    'challenges.examples[' + (department.challenges?.examples?.length ?? 0) + ']',
                                    $event.name
                                )
                            "
                            [isNew]="true"
                        ></grainger-icon-list-item>
                    </li>
                </ul>
            </ng-container>
        </div>
    </section>

    <section *ngIf="department.challenges?.conversationQuestions?.length || canEdit" class="section questions">
        <h3 class="section__heading questions__heading">
            <span class="questions__heading-text"
                >Conversation<br class="hide-desktop" />
                Questions</span
            >
            <span class="question-icon">
                <img class="questions-icon__img" src="assets/ui/icon-question-mark.svg" alt="?" />
            </span>
        </h3>
        <ul class="bullet-list question-list">
            <li
                *ngFor="let question of department.challenges?.conversationQuestions; let i = index"
                class="bullet-list__item question-list__item"
            >
                <grainger-icon-list-item
                    [link]="{ name: question | capitalizeFirstLetter }"
                    [fields]="{
                        name: 2
                    }"
                    (inlineSave)="updateDepartmentData('challenges.conversationQuestions[' + i + ']', $event.name)"
                    (inlineDelete)="deleteDepartmentDataItem('challenges.conversationQuestions', i)"
                ></grainger-icon-list-item>
            </li>
            <li *ngIf="canEdit" class="bullet-list__item question-list__item">
                <grainger-icon-list-item
                    [link]="{ name: '+ Add Conversation Question' }"
                    [fields]="{
                        name: 2
                    }"
                    (inlineSave)="
                        updateDepartmentData(
                            'challenges.conversationQuestions[' +
                                (department.challenges?.conversationQuestions?.length ?? 0) +
                                ']',
                            $event.name
                        )
                    "
                    [isNew]="true"
                ></grainger-icon-list-item>
            </li>
        </ul>
    </section>

    <section *ngIf="department.challenges?.dvpPings?.length || canEdit" class="section dvp">
        <h3 class="dvp__heading section__heading section__heading--small">DVP Pings</h3>

        <div class="dvp__grid desktop-only">
            <ul
                *ngFor="
                    let dvpPingColumn of department.challenges?.dvpPings | arrayToColumns: 4;
                    let i = index;
                    let last = last
                "
                class="bullet-list dvp-list"
            >
                <li *ngFor="let val of dvpPingColumn; let j = index" class="bullet-list__item">
                    <grainger-icon-list-item
                        [link]="{ name: val | capitalizeFirstLetter }"
                        [fields]="{
                            name: 2
                        }"
                        (inlineSave)="updateDepartmentData('challenges.dvpPings[' + (i * j + j) + ']', $event.name)"
                        (inlineDelete)="deleteDepartmentDataItem('challenges.dvpPings', i * j + j)"
                    ></grainger-icon-list-item>
                </li>
                <ng-container *ngIf="canEdit && department.challenges?.dvpPings?.length && last">
                    <ng-container *ngTemplateOutlet="placeholderDvpPing"> </ng-container>
                </ng-container>
            </ul>

            <ng-template #placeholderDvpPing>
                <li class="bullet-list__item">
                    <grainger-icon-list-item
                        [link]="{ name: '+ Add DVP Ping' }"
                        [fields]="{
                            name: 2
                        }"
                        (inlineSave)="
                            updateDepartmentData(
                                'challenges.dvpPings[' + (department.challenges?.dvpPings?.length ?? 0) + ']',
                                $event.name
                            )
                        "
                        [isNew]="true"
                    ></grainger-icon-list-item>
                </li>
            </ng-template>
            <ul *ngIf="canEdit && !department.challenges?.dvpPings?.length">
                <ng-container *ngTemplateOutlet="placeholderDvpPing; context: { length: 0 }"> </ng-container>
            </ul>
        </div>
        <ul class="bullet-list dvp-list mobile-only">
            <li
                *ngFor="let val of department.challenges?.dvpPings; let i = index"
                class="bullet-list__item"
                (appInlineInput)="updateDepartmentData('challenges.dvpPings[' + i + ']', $event)"
                (inlineDelete)="deleteDepartmentDataItem('challenges.dvpPings', i)"
            >
                {{ val }}
            </li>
            <li
                *ngIf="canEdit"
                class="bullet-list__item"
                (appInlineInput)="
                    updateDepartmentData(
                        'challenges.dvpPings[' + (department.challenges?.dvpPings?.length ?? 0) + ']',
                        $event
                    )
                "
                [isNew]="true"
            >
                + Add DVP Ping
            </li>
        </ul>
    </section>

    <div class="logo">
        <img src="assets/images/logo.svg" alt="Grainger Logo" class="logo__img" />
    </div>
</div>
