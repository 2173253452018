<div #menuContainer class="global-menu">
    <div class="global-menu__header">
        <h1 class="global-menu__title">Menu</h1>

        <span style="flex: 1"></span>
        <grainger-arrow-button
            *ngIf="currentPillar; else closeButton"
            text="Main Menu"
            [handleInParent]="true"
            (clicked)="backToMainMenu()"
            class="global-menu__back-button"
        ></grainger-arrow-button>

        <div class="c-edit" *ngIf="currentPillar && isAdmin">
            <button class="btn btn--edit" title="Toggle Editor Mode" (click)="toggleEditMode()">
                <svg>
                    <use xlink:href="assets/ui/icon-edit.svg#icon" *ngIf="!isEditing"></use>
                    <use xlink:href="assets/ui/icon-edit-off.svg#icon" *ngIf="isEditing"></use>
                </svg>
            </button>
        </div>

        <ng-template #closeButton>
            <div class="close-button" (click)="closeMenu()">
                <img src="assets/ui/icon-menu-close.png" alt="Close Icon" class="close-button__icon" />
            </div>
        </ng-template>
    </div>

    <ng-container *ngIf="facility">
        <section *ngIf="currentPillar; else mainMenu" class="c-content c-content--details pillar">
            <div class="left pillar">
                <div class="pillar__indented-content-mobile">
                    <ng-container *ngTemplateOutlet="linkList; context: { $implicit: currentPillar }"></ng-container>
                </div>

                <div class="c-pillar__img">
                    <img
                        [src]="'assets/images/pillars/menu-icons/' + currentPillar.id + '.svg'"
                        [alt]="'Image of ' + currentPillar.name"
                        class="pillar__img"
                    />
                </div>
            </div>

            <div class="pillar right">
                <div class="pillar__indented-content-mobile">
                    <h1 class="pillar__heading">
                        {{ currentPillar.name }}
                        <span class="pillar__heading--postfix">Details</span>
                    </h1>
                    <p
                        class="pillar__description"
                        (appInlineInput)="updatePillarOverview(currentPillar.id!, $event)"
                        [disableDelete]="true"
                        [rows]="6"
                    >
                        {{ currentPillar.overview || "+ Add Overview" }}
                    </p>
                </div>

                <div class="c-pillar__img c-pillar__img--mobile">
                    <img
                        [src]="'assets/images/pillars/menu-icons/' + currentPillar.id + '.svg'"
                        [alt]="'Image of ' + currentPillar.name"
                        class="pillar__img"
                    />
                </div>
            </div>
        </section>

        <ng-template #mainMenu>
            <div class="c-content">
                <section *ngFor="let pillar of facility.pillars" class="column">
                    <ng-container *ngTemplateOutlet="linkList; context: { $implicit: pillar }"></ng-container>
                </section>
            </div>
        </ng-template>
    </ng-container>

    <div class="global-menu__footer">
        <ul class="link-list link-list--footer link-list--small">
            <li class="link-list__item" *ngFor="let link of footerLinks">
                <grainger-icon-list-item
                    (clicked)="trackClick('Clicked footer link - ' + link.name)"
                    [link]="{
                        name: link.name,
                        url: link.url
                    }"
                    [disableEdit]="true"
                ></grainger-icon-list-item>
            </li>
        </ul>

        <div class="logo">
            <img src="assets/images/logo.svg" alt="Grainger logo" class="logo__img" />
        </div>
    </div>

    <ng-template #linkList let-pillar>
        <div class="c-link-list">
            <h2 class="link-list__heading">
                <a (click)="viewPillarDetails(pillar)">{{ pillar.name }}</a>
            </h2>

            <ul class="link-list">
                <ng-container *ngFor="let link of pillar.displayDepartments">
                    <li class="link-list__item">
                        <grainger-icon-list-item
                            [link]="{ name: link.name, url: 'department/' + link.urlSlug }"
                            [disableEdit]="true"
                        ></grainger-icon-list-item>
                    </li>
                </ng-container>
            </ul>
        </div>
    </ng-template>
</div>

<grainger-edit-mode-popover *ngIf="editModePopoverVisible"></grainger-edit-mode-popover>
