<div class="c-popover" @fadeIn>
    <div class="popover">
        <div class="popover-header">
            <h3>Are you sure?</h3>
        </div>
        <div class="popover-body">
            <p>Deleting this item is permanent - please proceed carefully.</p>
        </div>
        <div class="popover-footer">
            <button class="btn btn-clear" (click)="closePopover()">Cancel</button>
            <button class="btn btn-accent" (click)="closePopover('confirm')">Confirm</button>
        </div>
    </div>
</div>
