<grainger-form-page-wrapper>
    <h1 class="form__heading">Complete Your Profile</h1>
    <form *ngIf="form" class="form" [formGroup]="form">
        <div class="c-form-fields">
            <grainger-form-control class="form-control">
                <input type="text" formControlName="email" placeholder="Email" appInput="Email" />
            </grainger-form-control>

            <div class="two-col">
                <grainger-form-control>
                    <grainger-select
                        appInput="Sales Region / Function"
                        label="Sales Region / Function"
                        formControlName="regionOrFunction"
                        [options]="regionOrFunctionOptions"
                    ></grainger-select>
                </grainger-form-control>
                <grainger-form-control>
                    <grainger-select
                        appInput="District / Team"
                        label="District / Team"
                        formControlName="district"
                        [options]="districtOptions"
                    ></grainger-select>
                </grainger-form-control>
            </div>
        </div>

        <div class="c-buttons">
            <grainger-arrow-button
                class="form-btn"
                text="Save Profile"
                [handleInParent]="true"
                (clicked)="updateProfile()"
                arrowDirection="right"
            ></grainger-arrow-button>
        </div>

        <p *ngIf="hasSubmissionError" class="submission-error">
            {{ submissionError }}
        </p>
    </form>
</grainger-form-page-wrapper>

<div class="c-popup" *ngIf="isPopupVisible">
    <div class="popup">
        <div class="popup__head">
            <button class="close-btn" (click)="closePopup()"><span class="close-btn__icon"></span></button>
        </div>
        <div class="popup__body">
            <p class="popup__copy">Please complete your profile to view site.</p>

            <div class="c-btn">
                <grainger-arrow-button
                    arrowDirection="right"
                    text="Continue"
                    (clicked)="closePopup()"
                ></grainger-arrow-button>
            </div>
        </div>
    </div>
</div>
