<div class="pagination">
    <button class="icon-btn icon-btn--prev" [disabled]="isAtStart && hasMultiplePages" (click)="prevPage()"><</button>

    <div
        class="pages"
        [ngClass]="{
            'pages--at-start': hasPreviousItems,
            'pages--at-end': hasNextItems
        }"
    >
        <span *ngIf="hasPreviousItems" class="ellipsis">...</span>
        <button
            *ngFor="let pageNumber of shownPages"
            class="page-btn"
            [ngClass]="{ 'page-btn--active': pageNumber === currentPage }"
            (click)="goToPage(pageNumber)"
        >
            {{ pageNumber }}
        </button>
        <span *ngIf="hasNextItems" class="ellipsis">...</span>
    </div>
    <button class="icon-btn icon-btn--next" [disabled]="isAtEnd && hasMultiplePages" (click)="nextPage()">></button>
</div>
