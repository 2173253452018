<div class="dropdown">
    <div class="dropdown__trigger">
        <div class="dropdown__button">
            <ng-content></ng-content>
        </div>
        <div *ngIf="dropdown && dropdown.links?.length" class="dropdown__content dropdown__content--{{ position }}">
            <div class="dropdown__inner-content">
                <h3 class="dropdown__title">{{ dropdown.title }}</h3>
                <ul class="link-list dropdown__list">
                    <li
                        class="link-list__item dropdown__list-item"
                        *ngFor="let link of dropdown.links"
                        routerLinkActive="dropdown__list-item--active"
                    >
                        <grainger-icon-list-item
                            (clicked)="clicked.emit(link.name)"
                            [link]="link"
                            [activeRouteExactMatch]="false"
                            [disableEdit]="true"
                        ></grainger-icon-list-item>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
