export class ValidationErrorMessages {
    static getMessage(methodName: string, replacement?: string | number): string {
        const messageGetter: string | Function = ValidationErrorMessages[methodName as keyof ValidationErrorMessages];

        if (typeof messageGetter === "function" && ValidationErrorMessages.hasArgs(messageGetter)) {
            return (messageGetter as Function)(replacement);
        } else {
            return messageGetter;
        }
    }

    static hasArgs(func: Function): boolean {
        return func.length > 0;
    }

    static readonly required = (name: string) => `${name[0].toUpperCase() + name.slice(1)} is required`;
    static readonly email = "Please enter a valid email address";
    static readonly isEmail = ValidationErrorMessages.email;
    static readonly passwordMismatch = "Passwords do not match";
    static readonly fieldMismatch = (fieldNames?: { field1: string; field2: string }) => {
        if (!fieldNames) {
            return "Fields do not match";
        }
        return `${fieldNames.field1} and ${fieldNames.field2} do not match`;
    };
    static readonly min = (min: number) => `Minimum value: ${min}`;
    static readonly max = (max: number) => `Maximum value: ${max}`;
    static readonly minlength = (lengthInfo: { requiredLength: number; actualLength: number }) =>
        `At least ${lengthInfo.requiredLength} characters required`;
    static readonly maxlength = (lengthInfo: { requiredLength: number; actualLength: number }) =>
        `Maximum of ${lengthInfo.requiredLength} characters allowed`;
    static readonly invalidEmailDomain = "Please enter a valid Grainger email address";
}
