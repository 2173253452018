import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { AccordionItemComponent } from "../components/accordion-item/accordion-item.component";
import { BaseTabComponent } from "../components/for-extension-only/base-tab/base-tab.component";
import { IconListItemComponent } from "../components/icon-list-item/icon-list-item.component";
import { MobileDepartmentHeaderComponent } from "../components/mobile-department-header/mobile-department-header.component";
import { CustomAnimations } from "../core/animations/animations";
import { InlineInputDirective } from "../core/directives/inline-input.directive";
import { AccordionControl } from "../core/interfaces/accordion-control.interface";
import { CapitalizeFirstLetterPipe } from "../core/pipes/capitalize-first-letter.pipe";

@Component({
    selector: "grainger-embeds",
    standalone: true,
    imports: [
        CommonModule,
        AccordionItemComponent,
        IconListItemComponent,
        MobileDepartmentHeaderComponent,
        CapitalizeFirstLetterPipe,
        InlineInputDirective
    ],
    templateUrl: "./embeds.component.html",
    styleUrl: "./embeds.component.scss",
    animations: [CustomAnimations.fadeIn("0.3s")]
})
export class EmbedsComponent extends BaseTabComponent implements AccordionControl {
    public currentAccordionIndex: number | null = null;

    public updateAccordionIndex(index: number) {
        this.currentAccordionIndex = this.currentAccordionIndex === index ? null : index;
    }
}
