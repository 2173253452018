<div
    class="dollhouse-wrapper"
    [ngStyle]="
        !!department
            ? {
                  backgroundImage: 'url(assets/dollhouses/' + department.facility?.urlSlug + '.png)'
              }
            : {}
    "
></div>
<div class="content-wrapper">
    <div class="screen"></div>
    <grainger-subpage-header
        *ngIf="!!department && tabs && department.pillarInfo as pillarInfo"
        class="popup-header"
        [button]="{ name: 'Back to Map', url: '' }"
        [dropdownMenu]="{
            title: pillarInfo.pillarName || '',
            links: departmentLinks.length ? departmentLinks : []
        }"
        [hasFloatingButton]="true"
        [hasEditModeToggle]="true"
        [floatWithElement]="tabs"
        (buttonClicked)="trackHeaderClick('Clicked back to map')"
        (dropdownClicked)="trackHeaderClick('Clicked department link - ' + $event)"
    >
        <h1 slot="left" class="popup-header__heading">
            {{ pillarInfo.pillarName | titlecase }}
        </h1>
    </grainger-subpage-header>

    <main *ngIf="!!department" class="content">
        <div class="header">
            <div class="header__left">
                <h1 *ngIf="department.name" class="header__title">
                    {{ department.name }}
                </h1>
                <p
                    *ngIf="department.description || canEdit"
                    class="header__description"
                    (appInlineInput)="updateDepartmentDescription($event)"
                    [rows]="8"
                >
                    {{ department.description || "+ Add Description" }}
                </p>
            </div>

            <div class="header__right">
                <!-- <div class="c-dept-image">
          <img
            src="assets/images/departments/placeholder.png"
            [alt]="'View of ' + department.name + ' department'"
            class="dept-image"
          />
        </div> -->
            </div>
        </div>

        <div class="c-tabs">
            <div #tabs class="c-floating-tabs">
                <div class="tabs" [ngClass]="{ 'tabs--scrolled': tabScrolled }">
                    <div class="c-tab" *ngIf="canShowOverview">
                        <a
                            class="tab"
                            routerLink="overview"
                            routerLinkActive="active"
                            (click)="trackTabClick('Clicked tab - ' + 'Overview')"
                            >OVERVIEW</a
                        >
                    </div>
                    <div class="c-tab" *ngIf="canShowChallenges">
                        <a
                            class="tab"
                            routerLink="challenges"
                            routerLinkActive="active"
                            (click)="trackTabClick('Clicked tab - ' + 'Challenges')"
                            >CHALLENGES</a
                        >
                    </div>
                    <div class="c-tab" *ngIf="canShowSolutions">
                        <a
                            class="tab"
                            routerLink="solutions"
                            routerLinkActive="active"
                            (click)="trackTabClick('Clicked tab - ' + 'Solutions')"
                            >SOLUTIONS</a
                        >
                    </div>
                    <div class="c-tab" *ngIf="canShowEmbeds">
                        <a
                            class="tab"
                            routerLink="embeds"
                            routerLinkActive="active"
                            (click)="trackTabClick('Clicked tab - ' + 'Embeds')"
                            >EMBEDS</a
                        >
                    </div>
                    <div class="c-tab" *ngIf="canShowResources">
                        <a
                            class="tab"
                            routerLink="resources"
                            routerLinkActive="active"
                            (click)="trackTabClick('Clicked tab - ' + 'Resources')"
                            >RESOURCES</a
                        >
                    </div>
                    <div class="c-tab" *ngIf="canShowInsights">
                        <a
                            class="tab"
                            routerLink="insights"
                            routerLinkActive="active"
                            (click)="trackTabClick('Clicked tab - ' + 'Insights')"
                            >INSIGHTS</a
                        >
                    </div>
                </div>
            </div>
            <div class="page-content">
                <router-outlet></router-outlet>
            </div>
        </div>
    </main>
</div>
