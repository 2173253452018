/**
 *  This directive emits an event when a click or tap (on touch) event occurs outside of the host element.
 *
 * With the `clickOutsideDisabled` input, you can enable or disable the directive for a specific element.
 * This is useful when you have multiple elements that use the directive, but you only want one to be active
 * at a time or when you need to disable the directive for a specific use case.
 *
 */

import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from "@angular/core";

@Directive({
    selector: "[appClickOutside]",
    standalone: true
})
export class ClickOutsideDirective {
    @Output() appClickOutside: EventEmitter<void> = new EventEmitter<void>();

    @Input() clickOutsideDisabled: boolean = false;

    constructor(private el: ElementRef) {}

    @HostListener("document:click", ["$event"])
    @HostListener("document:touchstart", ["$event"])
    onClick(event: MouseEvent | TouchEvent) {
        if (this.clickOutsideDisabled) {
            return;
        }

        const target = event.target as HTMLElement;

        const clickedHost = this.el.nativeElement.contains(target);

        if (!clickedHost) {
            this.appClickOutside.emit();
        }
    }
}
