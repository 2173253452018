<div class="facility-landing">
    <div *ngIf="facility && isDesktop" class="ratio-container">
        <button class="btn nav-button-toggle" (click)="openGlobalMenu()">
            <div class="nav-button-toggle__icon">
                <img src="assets/ui/icon-hamburger.svg" alt="Menu Icon" />
            </div>

            <span class="nav-button-toggle__text">Menu</span>
        </button>
        <grainger-global-menu
            class="c-global-menu"
            *ngIf="isDesktop && globalMenuShowing"
            [facility]="facility"
            (menuClosed)="closeGlobalMenu()"
        >
        </grainger-global-menu>
        <grainger-dollhouse [facility]="facility"></grainger-dollhouse>
    </div>

    <ng-container *ngIf="facility && !isDesktop">
        <grainger-facility-mobile [facility]="facility" (menuOpened)="openGlobalMenu()"></grainger-facility-mobile>

        <grainger-global-menu
            class="c-global-menu c-global-menu--mobile"
            *ngIf="globalMenuShowing"
            [facility]="facility"
            (menuClosed)="closeGlobalMenu()"
        >
        </grainger-global-menu>
    </ng-container>
</div>
