<div class="dollhouse-wrapper"></div>
<div class="content-wrapper">
    <div class="screen"></div>
    <grainger-subpage-header
        class="popup-header"
        [button]="{ name: 'Back to Map', url: '' }"
        [hasFloatingButton]="false"
        (buttonClicked)="trackClick('Clicked back to map')"
    >
        <div slot="left" class="popup-header__logo">
            <img src="assets/images/logo.svg" alt="Grainger Logo" class="popup-header__logo-img" />
        </div>
    </grainger-subpage-header>

    <main class="content">
        <div class="header">
            <div class="header__left">
                <h1 class="header__title">Key Suppliers</h1>
                <p class="header__description">This is the paragraph that will appear on the key suppliers heading.</p>
            </div>

            <div class="header__right">
                <!-- <aside class="c-dept-image">
          <img
            src="assets/images/departments/placeholder.png"
            class="dept-image"
          />
        </aside> -->
            </div>
        </div>

        <div class="page-content">
            <div class="page-content__header">
                <h2>Supplier Details</h2>
                <grainger-search-bar class="search-bar" [searchControl]="searchControl"></grainger-search-bar>
            </div>

            <div class="suppliers">
                <div class="btn filter-toggle" (click)="toggleFilters()">
                    <span class="filter-toggle__indicator">
                        <span class="icon" [ngClass]="{ 'icon--active': !showFilters }">+</span>
                        <span class="icon icon--collapsed" [ngClass]="{ 'icon--active': showFilters }">-</span>
                    </span>
                    <span>{{ showFilters ? "Hide Filters" : "Show Filters" }}</span>
                </div>
                <div class="drawer">
                    <div
                        class="drawer__menu"
                        [ngClass]="{
                            'drawer__menu--open': showFilters
                        }"
                    >
                        <ng-container *ngFor="let filterGroup of filterGroups">
                            <div class="menu-section">
                                <h4 class="menu-section__heading">
                                    {{ filterGroup.title | titlecase }}
                                </h4>
                                <ul class="menu-section__filter-list">
                                    <li
                                        *ngFor="let control of filterGroup?.controls || []"
                                        class="menu-section__filter-item"
                                    >
                                        <grainger-checkbox
                                            [label]="control.name"
                                            [value]="control.value"
                                            (selectionChanged)="updateFilters(filterGroup.title, $event)"
                                        ></grainger-checkbox>
                                    </li>
                                </ul>
                            </div>
                        </ng-container>
                    </div>
                    <div class="drawer__content">
                        <grainger-supplier-accordion
                            class="c-accordion"
                            *ngIf="suppliers && suppliers.length; else noResults"
                            [heading]="null"
                            [suppliers]="suppliers"
                        >
                            <grainger-accordion-item
                                class="accordion-item"
                                *ngFor="let supplier of suppliers; let i = index; trackBy: trackSupplierBy"
                            >
                                <ng-container accordion-heading>
                                    <span>
                                        {{ supplier.name }}
                                    </span>
                                    <!-- <span
                                        (appInlineInput)="updateDepartmentData('suppliers[' + i + '].name', $event)"
                                        (inlineDelete)="deleteDepartmentDataItem('suppliers', i)"
                                        [rows]="2"
                                    >
                                        {{ supplier.name }}
                                    </span> -->
                                </ng-container>

                                <ng-container *ngIf="supplier.hasProducts || supplier.hasServices" accordion-icons>
                                    <div class="accordion-legend accordion-legend--no-labels">
                                        <div *ngIf="supplier.hasServices" class="accordion-legend__item">
                                            <div class="c-legend-icon c-legend-icon--service">
                                                <img
                                                    src="assets/ui/icon-service-black.png"
                                                    alt="Service Icon"
                                                    class="accordion-legend__img"
                                                />
                                            </div>
                                        </div>

                                        <div *ngIf="supplier.hasProducts" class="accordion-legend__item">
                                            <div class="c-legend-icon c-legend-icon--product">
                                                <img
                                                    src="assets/ui/icon-product-black.png"
                                                    alt="Product Icon"
                                                    class="accordion-legend__img"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container accordion-content>
                                    <h3 class="accordion-content__subtitle">Supplier Details</h3>
                                    <ng-container *ngIf="supplier.description?.length">
                                        <p
                                            *ngFor="let description of supplier.description; let j = index"
                                            class="accordion-content__copy"
                                        >
                                            {{ description | capitalizeFirstLetter }}
                                        </p>
                                    </ng-container>

                                    <ul *ngIf="supplier.resources?.length" class="link-list accordion-content__links">
                                        <li
                                            class="link-list__item accordion-content__link-item"
                                            *ngFor="let resource of supplier.resources; let j = index"
                                        >
                                            <grainger-icon-list-item
                                                [link]="resource"
                                                (clicked)="
                                                    trackClick(
                                                        'Clicked supplier link - ' +
                                                            supplier.name +
                                                            ' - ' +
                                                            resource.name
                                                    )
                                                "
                                            ></grainger-icon-list-item>
                                        </li>
                                    </ul>

                                    <div *ngIf="supplier.url || supplier.phoneNumber" class="c-button">
                                        <grainger-arrow-button
                                            class="accordion-content__button"
                                            text="Visit Supplier"
                                            [path]="supplier.url"
                                            arrowDirection="right"
                                            (clicked)="trackClick('Clicked supplier button - ' + supplier.name)"
                                        ></grainger-arrow-button>
                                        <span class="supplier__phone" *ngIf="supplier.phoneNumber">
                                            Phone:&nbsp;<span>
                                                {{ supplier.phoneNumber }}
                                            </span>
                                        </span>
                                    </div>
                                </ng-container>
                            </grainger-accordion-item>
                        </grainger-supplier-accordion>

                        <grainger-pagination
                            *ngIf="searchMetaData && (searchMetaData?.pageCount || 0) > 1"
                            class="pagination"
                            [totalPages]="searchMetaData.pageCount"
                            [currentPage]="searchMetaData.page"
                            (pageChanged)="updatePagination($event)"
                        ></grainger-pagination>
                    </div>

                    <ng-template #noResults>
                        <div class="no-results">
                            <h3 class="no-results__heading">No Suppliers Found</h3>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </main>
</div>
