import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";

@Component({
    selector: "grainger-search-bar",
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule],
    templateUrl: "./search-bar.component.html",
    styleUrl: "./search-bar.component.scss"
})
export class SearchBarComponent implements AfterViewInit {
    @Output() public searchChanged = new EventEmitter<string>();

    @Input() public searchControl: FormControl;

    ngAfterViewInit(): void {
        this.searchControl.valueChanges.subscribe((value) => {
            this.searchChanged.emit(value || "");
        });
    }

    public clearSearch(event: Event): void {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        this.searchControl.setValue("");
    }
}
