<div class="c-popover" @fadeIn>
    <div class="popover">
        <div class="popover-header">
            <h3>Editor Mode</h3>
        </div>
        <div class="popover-body">
            <p>Navigate each tab to browse department data.</p>
            <ul class="bullet-list">
                <li>
                    Click the "Edit" button (
                    <svg class="inline-edit-icon">
                        <use xlink:href="assets/ui/icon-edit.svg#icon"></use>
                    </svg>
                    ) to edit an element.
                </li>
                <li>
                    Click the "Trash" button (
                    <svg class="inline-trash-icon">
                        <use xlink:href="assets/ui/icon-trash.svg#icon"></use>
                    </svg>
                    ) to delete an element.
                </li>
            </ul>
            <p>While editing - Keyboard Shortcuts</p>
            <ul class="bullet-list">
                <li>
                    <span>Press "Enter" to save changes.</span>
                </li>
                <li>
                    <span>Press "Esc" to cancel changes.</span>
                </li>
            </ul>
            <p>
                To exit Editor mode, click the toggle button (
                <svg class="inline-edit-icon">
                    <use xlink:href="assets/ui/icon-edit-off.svg#icon"></use>
                </svg>
                ) at the top right of this page
            </p>
        </div>
        <div class="popover-footer">
            <button class="btn btn-accent" (click)="close()">Close</button>
        </div>
    </div>
</div>
