import { CommonModule, DOCUMENT } from "@angular/common";
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    Renderer2,
    ViewContainerRef,
    inject
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute } from "@angular/router";
import { IDepartment } from "@grainger/common/definitions/department";
import { cloneDeep as _cloneDeep, get as _get, set as _set } from "lodash";
import { take, tap } from "rxjs";
import { AnalyticsService } from "../../../core/services/analytics.service";
import { DepartmentService } from "../../../core/services/department.service";
import { EditService } from "../../../core/services/edit.service";
import { ConfirmDeletePopoverComponent } from "../../confirm-delete-popover/confirm-delete-popover.component";

@Component({
    selector: "grainger-base-tab",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./base-tab.component.html",
    styleUrl: "./base-tab.component.scss"
})
export class BaseTabComponent implements AfterViewInit {
    private editService = inject(EditService);
    private changeDetector = inject(ChangeDetectorRef);
    private route = inject(ActivatedRoute);
    private analyticsService = inject(AnalyticsService);
    private cdr = inject(ChangeDetectorRef);
    private departmentsService = inject(DepartmentService);
    private renderer = inject(Renderer2);
    private destroyRef = inject(DestroyRef);
    private vcr = inject(ViewContainerRef);
    private document = inject(DOCUMENT);

    public canEdit = false;

    public department: IDepartment | null = null;

    ngAfterViewInit(): void {
        this.route.parent?.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data) => {
            this.department = data["department"] ?? null;
            this.changeDetector.detectChanges();
        });

        this.editService.isEditing$
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                tap((canEdit) => {
                    this.canEdit = canEdit;
                    this.changeDetector.detectChanges();
                })
            )
            .subscribe();
    }

    public trackMobileBackClick(tabName: string): void {
        this.analyticsService.trackInteraction(
            {
                action: "click",
                location: "Department Page",
                category: "button",
                label: "Clicked mobile back arrow"
            },
            {
                facility: this.department?.facility?.name,
                pillar: this.department?.displayDepartments?.pillarName,
                department: this.department?.name,
                department_tab: tabName
            }
        );
    }

    public trackClick(label: string, tabName?: string): void {
        this.analyticsService.trackInteraction(
            {
                action: "click",
                location: "Department Page" + (tabName ? ` - ${tabName[0].toUpperCase() + tabName.slice(1)}` : ""),
                category: "button",
                label
            },
            {
                facility: this.department?.facility?.name,
                pillar: this.department?.displayDepartments?.pillarName,
                department: this.department?.name,
                department_tab: tabName || null
            }
        );
    }

    public updateDepartmentData(path: string, value: unknown) {
        const department = _cloneDeep(this.department!);
        _set(department, path, value);

        this.departmentsService.updateDepartment(this.department!.urlSlug, department).subscribe((res) => {
            this.department = res;
            this.cdr.detectChanges();
        });
    }

    public deleteDepartmentDataItem(path: string, index: number) {
        this.showDeletePopover(() => {
            const department = _cloneDeep(this.department!);
            const value = _get(department, path);
            if (Array.isArray(value)) {
                value.splice(index, 1);
                _set(department, path, value);
            } else {
                _set(department, path, null);
            }

            this.departmentsService.updateDepartment(this.department!.urlSlug, department).subscribe((res) => {
                this.department = res;
                this.cdr.detectChanges();
            });
        });
    }

    private showDeletePopover(callback: () => void) {
        const componentRef = this.vcr.createComponent(ConfirmDeletePopoverComponent);
        this.renderer.appendChild(this.document.body, componentRef.location.nativeElement);
        componentRef.instance.close.pipe(take(1)).subscribe((reason) => {
            if (reason === "confirm") {
                callback();
            }
            componentRef.destroy();
        });
    }
}
