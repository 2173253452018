import { CommonModule } from "@angular/common";
import { AfterViewInit, ChangeDetectorRef, Component, DestroyRef, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { ILinkableResource } from "@grainger/common/definitions/common";
import { IDepartmentUi } from "@grainger/common/definitions/department";
import { combineLatestWith } from "rxjs";
import { SubpageHeaderComponent } from "../../components/subpage-header/subpage-header.component";
import { Breakpoint } from "../../core/enums/breakpoint.enum";
import { AnalyticsService } from "../../core/services/analytics.service";
import { WindowSizeService } from "../../core/services/window-size.service";
import { DepartmentComponent } from "../department.component";
import { MobileDepartmentComponent } from "../mobile-department/mobile-department.component";

@Component({
    selector: "grainger-department-landing",
    standalone: true,
    imports: [CommonModule, SubpageHeaderComponent, RouterModule, DepartmentComponent, MobileDepartmentComponent],
    templateUrl: "./department-landing.component.html",
    styleUrl: "./department-landing.component.scss"
})
export class DepartmentLandingComponent implements AfterViewInit {
    private activatedRoute = inject(ActivatedRoute);
    private router = inject(Router);
    private changeDetector = inject(ChangeDetectorRef);
    private windowSizeService = inject(WindowSizeService);
    private destroy$ = inject(DestroyRef);
    private analyticsService = inject(AnalyticsService);

    public isDesktop: boolean = false;

    public department: IDepartmentUi | null = null;

    public departmentLinks: ILinkableResource[] = [];

    ngAfterViewInit(): void {
        this.activatedRoute?.data
            .pipe(combineLatestWith(this.windowSizeService.getWidth()), takeUntilDestroyed(this.destroy$))
            .subscribe(([data, width]) => {
                const prevDept = this.department;

                this.department = data["department"] ?? null;

                if (this.department != prevDept) {
                    // Don't repeat page views from redirects and resizing
                    this.analyticsService.trackPageView(
                        {
                            pageTitle: "Department Page",
                            label: `${this.department?.name} Department Page Viewed`
                        },
                        {
                            facility: this.department?.facility?.name,
                            pillar: this.department?.pillarInfo.pillarName,
                            department: this.department?.name
                        }
                    );
                }

                this.changeDetector.detectChanges();
                this.departmentLinks = this.getNormalizedDepartmentLinks(
                    this.department?.pillarInfo.displayDepartments || []
                );
                this.isDesktop = width > Breakpoint.Desktop;
                if (this.isDesktop && this.router.url === `/department/${this.department?.urlSlug}`) {
                    this.router.navigate([`/department/${this.department?.urlSlug}`, "overview"]);
                }

                this.changeDetector.detectChanges();
            });
    }

    private getNormalizedDepartmentLinks(links: ILinkableResource[]): ILinkableResource[] {
        return links.map((link) => {
            return {
                ...link,
                url: "/department/" + link.url
            };
        });
    }
}
