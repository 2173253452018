const UNSORTED_DISTRICTS = [
    "300",
    "301",
    "302",
    "303",
    "304",
    "305",
    "306",
    "307",
    "308",
    "332",
    "352",
    "390",
    "311",
    "312",
    "313",
    "314",
    "315",
    "316",
    "317",
    "318",
    "319",
    "330",
    "366",
    "320",
    "322",
    "323",
    "324",
    "325",
    "326",
    "327",
    "329",
    "333",
    "335",
    "382",
    "B03",
    "Brent Zinn",
    "Andrea Hamby",
    "Allison Darling",
    "Team Al Johnson",
    "Team Mark Snead",
    "Govt/HC Ops & Strategy",
    "353",
    "354",
    "356",
    "359",
    "364",
    "367",
    "374",
    "376",
    "379",
    "357",
    "358",
    "361",
    "365",
    "369",
    "373",
    "375",
    "377",
    "501",
    "522",
    "524",
    "599",
    "512",
    "805",
    "B02",
    "Team Jelovich",
    "Team Roach",
    "Team Rodman",
    "Team Snell",
    "58 I",
    "58 Y",
    "Canada District",
    "Puerto Rico District",
    "Commercial District",
    "Manufacturing Sales",
    "National Accounts",
    "FSS East",
    "FSS West",
    "FSS Central",
    "N/A"
] as const;

export const DISTRICTS = Array.from(UNSORTED_DISTRICTS).sort((a, b) => a.localeCompare(b));
