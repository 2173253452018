<grainger-form-page-wrapper>
    <h1 class="form__heading">Forgot Password</h1>
    <form *ngIf="form" class="form" [formGroup]="form">
        <div class="c-form-fields">
            <grainger-form-control class="form-control">
                <input type="text" formControlName="email" placeholder="Email" appInput="email" />
            </grainger-form-control>
        </div>

        <div class="c-buttons">
            <div class="c-form-btn">
                <grainger-arrow-button
                    [disabled]="isLoading"
                    class="form-btn"
                    text="Submit"
                    [handleInParent]="true"
                    (clicked)="resetPassword()"
                    [disabled]="isLoading"
                    arrowDirection="right"
                ></grainger-arrow-button>
            </div>
            <div class="form-change-link">
                <a
                    class="form-change-link__text"
                    [routerLink]="['/', 'login']"
                    (click)="trackClick('Clicked cancel', 'Forgot Password')"
                    >Cancel</a
                >
            </div>
        </div>

        <p *ngIf="hasSubmissionError" class="submission-error">
            {{submissionError}}
        </p>

        <p *ngIf="submissionMessage" class="submission-success">
            {{ submissionMessage }}
        </p>
    </form>
</grainger-form-page-wrapper>
