<div
    #container
    class="dollhouse-wrapper"
    [ngStyle]="
        facility?.id
            ? {
                  backgroundImage: 'url(' + 'assets/dollhouses/' + facility?.urlSlug + '.png)'
              }
            : null
    "
    (click)="debugMode && logMouseCoordinatesAsPercentOfContainer($event)"
>
    <ng-container *ngIf="facility && facility.pillars as pillars">
        <div class="logo">
            <img src="assets/images/logo.svg" alt="Grainger Logo" class="logo__img" />
        </div>
        <div
            *ngIf="debugMode && debugCoordinates"
            class="pillar pillar--debug"
            [ngStyle]="{
                left: debugCoordinates.x + 'cqw',
                top: debugCoordinates.y + 'cqw'
            }"
            [class.pillar--active]="'debug' === selectedPillarId"
            (click)="selectPillar($event, 'debug')"
            (appClickOutside)="selectedPillarId === 'debug' && deselectPillar()"
            [clickOutsideDisabled]="selectedPillarId !== 'debug'"
        >
            <div class="pillar__icon"></div>
            <span class="pillar__menu">
                Debugging Pillar
                <ul class="custom-ul">
                    <li>Debug dept 1</li>
                    <li>Debug dept 2</li>
                </ul></span
            >
        </div>
        <div
            *ngFor="let pillar of pillars"
            class="pillar"
            [ngStyle]="{
                left: pillar.dollhouseProperties.x + 'cqw',
                top: pillar.dollhouseProperties.y + 'cqw'
            }"
            [class.pillar--active]="pillar.id === selectedPillarId"
            (appClickOutside)="deselectPillar()"
            [clickOutsideDisabled]="selectedPillarId !== pillar.id"
        >
            <div
                class="pillar__icon"
                (click)="selectPillar($event, pillar.id!); trackClick('Clicked map icon - ' + pillar.name)"
            ></div>
            <div class="pillar__menu">
                <h3 class="pillar__heading">{{ pillar.name }}</h3>
                <ul class="link-list pillar-list">
                    <li *ngFor="let department of pillar.displayDepartments" class="link-list__item pillar-list__item">
                        <grainger-icon-list-item
                            (clicked)="
                                trackClick(
                                    'Clicked department link for pillar: ' + pillar.name + ' | ' + department.name
                                )
                            "
                            [link]="{
                                name: department.name,
                                url: 'department/' + department.urlSlug
                            }"
                            [disableEdit]="true"
                        ></grainger-icon-list-item>
                    </li>
                </ul>
            </div>
        </div>
        <div class="navbar">
            <div
                class="nav-item"
                *ngFor="let pillar of pillars"
                (click)="selectPillar($event, pillar.id!); trackClick('Clicked pillar menu item - ' + pillar.name)"
                [class.nav-item-active]="pillar.id === selectedPillarId"
            >
                <span class="red-circle"></span>
                <span class="nav-text">{{ pillar.name }}</span>
            </div>
        </div>
    </ng-container>
</div>
