const SALES_REGIONS_AND_FUNCTIONS_UNSORTED = [
    "Govt - North",
    "Govt - South Central",
    "Govt - West",
    "Govt Sales Manager - North",
    "Govt Sales Manager - South Central",
    "Govt Sales Manager - West",
    "Govt - National Sales",
    "Govt/HC Ops & Strategy",
    "Healthcare - West",
    "Healthcare - East",
    "Healthcare Corp Sales Team",
    "Inside Sales",
    "Canada",
    "Puerto Rico",
    "Commercial Sales",
    "Manufacturing Sales",
    "National Accounts",
    "FSS Team",
    "Other",
    "Corporate Office"
] as const;

export const SALES_REGIONS_AND_FUNCTIONS = Array.from(SALES_REGIONS_AND_FUNCTIONS_UNSORTED).sort((a, b) =>
    a.localeCompare(b)
);
