<div class="c-search">
    <input [formControl]="searchControl" class="search" type="text" placeholder="Search" tabindex="0" />
    <button
        tabindex="0"
        *ngIf="searchControl.value"
        class="clear-btn"
        (click)="clearSearch($event)"
        title="Clear Search"
    >
        <img class="clear-btn__icon" src="assets/ui/icon-clear-search.svg" alt="Clear Search" />
    </button>
</div>
