import { CommonModule } from "@angular/common";
import { Component, ContentChild, HostBinding, Input, OnInit } from "@angular/core";
import { InputDirective } from "../../core/directives/input.directive";
import { ValidationErrorMessages } from "../../core/utils/validation-error-messages.util";

@Component({
    standalone: true,
    selector: "grainger-form-control",
    templateUrl: "./form-control.component.html",
    styleUrls: ["./form-control.component.scss"],
    imports: [CommonModule, InputDirective]
})
export class FormControlComponent implements OnInit {
    @Input() errorClass: string = "";
    @Input() disabled: boolean = false;

    @ContentChild(InputDirective, { static: true })
    public currentInput!: InputDirective;

    constructor() {}

    ngOnInit(): void {
        if (!this.currentInput) {
            throw new Error(`${FormControlComponent.name} must contain ${InputDirective.name}`);
        }
    }

    @HostBinding("class.error")
    public get hasError(): boolean {
        return !!this.currentInput.control?.touched && Object.entries(this.currentInput.errors).length > 0;
    }

    public get currentError(): string | null {
        if (!this.currentInput.errors) {
            return null;
        }

        const errorMessages = Object.entries(this.currentInput.errors);

        if (errorMessages.length === 0) {
            return null;
        }

        const allMessages = errorMessages.map(([key, value]) => {
            if (typeof value === "boolean") {
                return ValidationErrorMessages.getMessage(key, this.currentInput.fieldName) || value;
            }

            // Custom values will be passed directly
            return ValidationErrorMessages.getMessage(key, value) || value;
        });

        return allMessages.length > 0 ? allMessages[0] : null;
    }
}
