import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IPillerDisplayDepartment } from "@grainger/common/definitions/common";
import {
    IDepartment,
    IDepartmentResponse,
    IDepartmentUi,
    IDepartmentUiPillarInfo
} from "@grainger/common/definitions/department";
import { UpdateDepartmentDto } from "@grainger/common/dto/update-department.dto";
import { Observable, map, of } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class DepartmentService {
    private _cachedDepartments: Map<string, IDepartmentUi> = new Map<string, IDepartmentUi>();

    constructor(private http: HttpClient) {}

    public getDepartment(id: string): Observable<IDepartmentUi | null> {
        if (!id) {
            throw new Error("Department id is undefined");
        }

        let department: IDepartmentUi | null = null;

        if (this._cachedDepartments.has(id)) {
            // Use cached value
            department = this._cachedDepartments.get(id) ?? null;
        } else {
            return this.getDeptFromApi(id);
        }

        return of(department ?? null);
    }

    public getDeptFromApi(urlSlug: string): Observable<IDepartmentUi | null> {
        return this.http
            .get<IDepartmentResponse>(`${environment.baseUrl}/facilities/test-facility/department/${urlSlug}`)
            .pipe(
                map(({ data }) => {
                    const deptData = data;

                    if (!deptData) {
                        throw new Error("No Department found");
                    }

                    const resolvedDept = this.resolveDepartment(deptData);

                    this._cachedDepartments.set(urlSlug, resolvedDept);

                    return resolvedDept;
                })
            );
    }

    public updateDepartment(urlSlug: string, department: UpdateDepartmentDto): Observable<IDepartment> {
        return this.http.patch<IDepartmentResponse>(`${environment.baseUrl}/departments/${urlSlug}`, department).pipe(
            map(({ data }) => {
                const deptData = data;
                if (!deptData) {
                    throw new Error("No Department found");
                }
                const resolvedDept = this.resolveDepartment(deptData);

                this._cachedDepartments.set(urlSlug, resolvedDept);
                return resolvedDept;
            })
        );
    }

    private resolveDepartment(department: IDepartment): IDepartmentUi {
        const pillar: IPillerDisplayDepartment | null = department.displayDepartments || null;

        if (!pillar) {
            throw new Error("No Pillar found on department");
        }
        //   // Conversion for use in UI
        const pillarInfo: IDepartmentUiPillarInfo = {
            pillarName: pillar?.pillarName || "",
            displayDepartments: (pillar?.displayDepartments || []).map((department) => {
                return {
                    name: department.name || "",
                    url: department.urlSlug || ""
                };
            })
        };

        const resolvedDept: IDepartmentUi = {
            ...department,
            pillarInfo
        };

        return resolvedDept;
    }
}
