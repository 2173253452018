<div class="c-control-content">
    <div class="form-control">
        <ng-content></ng-content>
    </div>
    <div *ngIf="hasError" class="form-control__errors">
        <p *ngIf="currentError" class="error-message">
            {{ currentError }}
        </p>
    </div>
</div>
