import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RouterModule } from "@angular/router";
import { DropdownMenu } from "../../core/definitions/dropdown-menu";
import { IconListItemComponent } from "../icon-list-item/icon-list-item.component";

@Component({
    selector: "grainger-dropdown-nav",
    standalone: true,
    imports: [CommonModule, IconListItemComponent, RouterModule],
    templateUrl: "./dropdown-nav.component.html",
    styleUrl: "./dropdown-nav.component.scss"
})
export class DropdownNavComponent {
    @Output() clicked: EventEmitter<string> = new EventEmitter<string>();

    @Input() dropdown: DropdownMenu | null = null;
    @Input() position: "right" | "below" = "below";
}
