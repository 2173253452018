<div class="accordion-item" [ngClass]="{ 'accordion-item--expanded': isOpen }">
    <div class="accordion-item__header" (click)="selectItem()">
        <div class="header-left">
            <span class="accordion-item__indicator">
                <span class="icon" [ngClass]="{ 'icon--active': !isOpen }">+</span>
                <span class="icon icon--collapsed" [ngClass]="{ 'icon--active': isOpen }">-</span>
            </span>
            <h2 class="accordion-item__heading">
                <ng-content select="[accordion-heading]"></ng-content>
            </h2>
        </div>
        <div class="header-right">
            <ng-content select="[accordion-icons]"></ng-content>
        </div>
    </div>
    <div class="accordion-item__expanding-container">
        <div class="accordion-item__content">
            <ng-content select="[accordion-content]"></ng-content>
        </div>
    </div>
</div>
