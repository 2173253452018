import { EnvironmentProviders, Provider } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { LoadingService } from "../services/loading.service";
import { StorageService } from "../services/storage.service";
import { WindowSizeService } from "../services/window-size.service";

export const CUSTOM_GLOBAL_PROVIDERS: (Provider | EnvironmentProviders)[] = [
    { provide: WindowSizeService, useClass: WindowSizeService },
    { provide: LoadingService, useClass: LoadingService },
    { provide: StorageService, useClass: StorageService },
    { provide: AuthService, useClass: AuthService }
];
