import { CommonModule } from "@angular/common";
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    inject
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ILinkableResource } from "@grainger/common/definitions/common";
import { IFacility } from "@grainger/common/definitions/facility";
import { IPillar } from "@grainger/common/definitions/pillar";
import { cloneDeep as _cloneDeep } from "lodash";
import { InlineInputDirective } from "../../core/directives/inline-input.directive";
import { AnalyticsService } from "../../core/services/analytics.service";
import { AuthService } from "../../core/services/auth.service";
import { EditService } from "../../core/services/edit.service";
import { PillarService } from "../../core/services/pillar.service";
import { ArrowButtonComponent } from "../arrow-button/arrow-button.component";
import { EditModePopoverComponent } from "../edit-mode-popover/edit-mode-popover.component";
import { IconListItemComponent } from "../icon-list-item/icon-list-item.component";

@Component({
    selector: "grainger-global-menu",
    standalone: true,
    imports: [
        CommonModule,
        IconListItemComponent,
        ArrowButtonComponent,
        InlineInputDirective,
        EditModePopoverComponent
    ],
    templateUrl: "./global-menu.component.html",
    styleUrl: "./global-menu.component.scss"
})
export class GlobalMenuComponent implements AfterViewInit {
    @Output() menuClosed: EventEmitter<void> = new EventEmitter<void>();

    @Input() facility: IFacility | null;

    public isEditing: boolean = false;
    public isAdmin: boolean = false;
    public editModePopoverVisible: boolean = false;

    @ViewChild("menuContainer") menuContainer: ElementRef<HTMLDivElement>;

    private readonly analticsService = inject(AnalyticsService);
    private readonly pillarService = inject(PillarService);
    private readonly editService = inject(EditService);
    private readonly auth = inject(AuthService);
    private readonly cdr = inject(ChangeDetectorRef);
    private readonly destroyRef = inject(DestroyRef);

    public readonly pdfBaseUrl: string = "https://storage.googleapis.com/grainger-assets/";

    public currentPillar: IPillar | null = null;
    public footerLinks: ILinkableResource[] = [
        {
            name: "HE Playbook",
            url: "https://linkprotect.cudasvc.com/url?a=https%3a%2f%2fgrainger.sharepoint.com%2fsites%2fSalesPlaybooks%2f_layouts%2f15%2fembed.aspx%3fUniqueId%3d3faad77c-64db-4609-83b9-d5ffdfb4b78e%26OR%3dTeams-HL%26CT%3d1704999957768&c=E,1,mbqAcxkMMd3dEXD4objJtZ0RopzIomoyA3RGVLMv6-SdqLyevvj-vv2V28jwpnmpPdolNIpQa-rx3XBNyAAa7x-7BKw84cSELWaXaGKHsayde-D8&typo=1"
        },
        {
            name: "HE Capabilities Presentation",
            url: this.pdfBaseUrl + "815562 - Education Capabilities Deck_2024_03.pptx"
        },
        {
            name: "HE Business Review Template",
            url: this.pdfBaseUrl + "Higher Ed_Business Review Template - 2024.pptx"
        },
        {
            name: "Sales Execution Guide",
            url: this.pdfBaseUrl + "2024 - Higher Education Sales Guide - INTERNAL ONLY.pdf"
        },
        {
            name: "HE BR Playbook",
            url: this.pdfBaseUrl + "QBR Playbook - 2024.pdf"
        },
        // {
        //     name: "Contracts",
        //     url: "#"
        // },
        {
            name: " Key Supplier List",
            url: "/suppliers"
        }
    ];

    ngAfterViewInit(): void {
        this.editService.isEditing$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((canEdit) => {
            this.isEditing = canEdit;
        });
        this.editService.editModePopoverVisible$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isVisible) => {
            this.editModePopoverVisible = isVisible;
        });

        this.auth.isAdmin$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isAdmin) => {
            this.isAdmin = isAdmin;
        });
    }

    public closeMenu(): void {
        this.menuClosed.emit();
    }

    public viewPillarDetails(pillar: IPillar): void {
        this.trackClick(`Clicked pillar - ${pillar.name}`);

        this.currentPillar = pillar;

        const el = this.menuContainer.nativeElement;
        this.scrollToTop(el);
    }

    public backToMainMenu(): void {
        this.trackClick("Clicked main menu");

        this.currentPillar = null;

        const el = this.menuContainer.nativeElement;
        this.scrollToTop(el);
    }

    public trackClick(label: string): void {
        this.analticsService.trackInteraction(
            {
                location: "Global Menu",
                action: "click",
                category: "button",
                label
            },
            { facility: this.facility?.name }
        );
    }

    private scrollToTop(el: HTMLElement) {
        el.scrollTo({ top: 0, left: 0 });
    }

    public toggleEditMode(): void {
        this.editService.toggleEditMode();
        if (!this.editModePopoverVisible && this.isEditing) {
            this.editService.toggleEditModePopover();
        }
    }

    public updatePillarOverview(pillarId: string, value: string): void {
        const pillar = _cloneDeep(this.currentPillar!);
        pillar.overview = value;
        this.pillarService
            .updatePillar(pillar.id!, {
                overview: pillar.overview
            })
            .subscribe((res) => {
                console.log(res);
                this.currentPillar = res;
                this.cdr.detectChanges();
            });
    }
}
