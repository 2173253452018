import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class LoadingService {
    private _isLoading: Subject<boolean> = new Subject<boolean>();

    public getLoadingStatus() {
        return this._isLoading.asObservable();
    }

    public setLoadingStatus(isLoading: boolean) {
        this._isLoading.next(isLoading);
    }
}
