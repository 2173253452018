import { CommonModule } from "@angular/common";
import { Component, ElementRef, Input, OnInit, ViewChild, inject } from "@angular/core";
import { Router } from "@angular/router";
import { IFacility } from "@grainger/common/definitions/facility";
import { ClickOutsideDirective } from "../../core/directives/click-outside.directive";
import { AnalyticsService } from "../../core/services/analytics.service";
import { UtilityService } from "../../core/services/utility.service";
import { IconListItemComponent } from "../icon-list-item/icon-list-item.component";

@Component({
    selector: "grainger-dollhouse",
    standalone: true,
    imports: [CommonModule, ClickOutsideDirective, IconListItemComponent],
    templateUrl: "./dollhouse.component.html",
    styleUrl: "./dollhouse.component.scss"
})
export class DollhouseComponent implements OnInit {
    @Input() public facility: IFacility | null;

    @ViewChild("container") container: ElementRef<HTMLDivElement>;

    // Dependencies
    public utilityService = inject(UtilityService);
    private router = inject(Router);
    private analyticsService = inject(AnalyticsService);

    public selectedPillarId: string = "";

    public debugMode: boolean = false;

    // Coordinates as percent of container
    public debugCoordinates: { x: number; y: number } = {
        x: 84.28,
        y: 18.76
    };

    constructor() {}

    ngOnInit(): void {}

    public deselectPillar() {
        this.selectedPillarId = "";
    }

    public selectPillar(event: any, pillarId: string): void {
        if (event) {
            event.stopPropagation();
        }

        this.selectedPillarId = pillarId;
    }

    public gotoDepartment(departmentSlug: string) {
        this.router.navigate(["department", departmentSlug]);
    }

    public trackClick(label: string): void {
        this.analyticsService.trackInteraction(
            {
                action: "click",
                location: "Facility Page",
                category: "button",
                label
            },
            { facility: this.facility?.name }
        );
    }

    // Use in debug mode
    public logMouseCoordinatesAsPercentOfContainer(event: MouseEvent): void {
        if (!this.debugMode) {
            return;
        }

        const rect = (event.target as HTMLElement).getBoundingClientRect();
        const x = event.clientX - rect.left; // X coordinate within the element
        const y = event.clientY - rect.top; // Y coordinate within the element
        console.log(`x: ${this.getContainerUnits(x)}%, y: ${this.getContainerUnits(y)}%`);
    }

    private getContainerUnits(pxFromEdge: number): number {
        const containerWidth = this.container?.nativeElement.getBoundingClientRect().width;

        return this.utilityService.convertToPercentOfContainer(pxFromEdge, containerWidth);
    }
}
