import { CommonModule } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router, RouterLink } from "@angular/router";
import { DISTRICTS } from "@grainger/common/constants/district.constants";
import { SALES_REGIONS_AND_FUNCTIONS } from "@grainger/common/constants/sales-region-function.constants";
import { IUser } from "@grainger/common/definitions/user";
import { catchError, delay, filter, take } from "rxjs";
import { ArrowButtonComponent } from "../components/arrow-button/arrow-button.component";
import { BaseAuthFormComponent } from "../components/base-auth-form/base-auth-form.component";
import { FormControlComponent } from "../components/form-control/form-control.component";
import { FormPageWrapperComponent } from "../components/form-page-wrapper/form-page-wrapper.component";
import { SelectComponent } from "../components/select/select.component";
import { InputDirective } from "../core/directives/input.directive";
import { CustomValidators } from "../core/validators/custom-validators.validator";

@Component({
    selector: "grainger-complete-profile",
    standalone: true,
    imports: [
        CommonModule,
        FormPageWrapperComponent,
        ReactiveFormsModule,
        FormControlComponent,
        SelectComponent,
        ArrowButtonComponent,
        InputDirective,
        RouterLink
    ],
    templateUrl: "./complete-profile.component.html",
    styleUrl: "./complete-profile.component.scss"
})
export class CompleteProfileComponent extends BaseAuthFormComponent implements OnInit {
    private router = inject(Router);

    public readonly regionOrFunctionOptions = SALES_REGIONS_AND_FUNCTIONS;
    public readonly districtOptions = DISTRICTS;

    public isPopupVisible: boolean = true;

    override ngOnInit(): void {
        super.ngOnInit();

        this.analyticsService.trackPageView({ pageTitle: "Complete Profile" });

        this.authService.user$.pipe(filter(Boolean), take(1)).subscribe((user) => {
            this.form = this.initForm(user!);
        });
    }

    protected initForm(user?: IUser): FormGroup {
        return this.fb.group({
            email: this.fb.control({ value: user?.email, disabled: true }, [
                Validators.required,
                CustomValidators.email
            ]),
            regionOrFunction: this.fb.control(user?.regionOrFunction, [Validators.required]),
            district: this.fb.control(user?.district, [Validators.required])
        });
    }

    public updateProfile(): void {
        this.trackClick("Clicked update profile", "Complete Profile Page");
        this.hasSubmissionError = false;

        if (this.form.invalid) {
            this.form.markAllAsTouched();
            this.form.updateValueAndValidity();

            return;
        }

        const { regionOrFunction, district } = this.form.value;

        this.authService
            .updateProfile({
                regionOrFunction,
                district
            })
            .pipe(
                delay(500),
                catchError((err) => {
                    this.hasSubmissionError = true;
                    this.submissionError = err.message;
                    throw Error("Error completing your profile: " + err.message);
                })
            )
            .subscribe(() => {
                this.router.navigate(["/"]);
            });
    }

    public closePopup(): void {
        this.trackClick("Clicked close popup", "Complete Profile Page");
        this.isPopupVisible = false;
    }

    private get email(): FormControl {
        return this.form.get("email") as FormControl;
    }
}
