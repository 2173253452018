import { CommonModule } from "@angular/common";
import { AfterViewInit, ChangeDetectorRef, Component, DestroyRef, Input, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { IFacility } from "@grainger/common/definitions/facility";
import { combineLatest, tap } from "rxjs";
import { DollhouseComponent } from "../../components/dollhouse/dollhouse.component";
import { FacilityMobileComponent } from "../../components/facility-mobile/facility-mobile.component";
import { GlobalMenuComponent } from "../../components/global-menu/global-menu.component";
import { Breakpoint } from "../../core/enums/breakpoint.enum";
import { AnalyticsService } from "../../core/services/analytics.service";
import { FacilityService } from "../../core/services/facility.service";
import { WindowSizeService } from "../../core/services/window-size.service";

@Component({
    selector: "grainger-facility",
    standalone: true,
    imports: [CommonModule, DollhouseComponent, GlobalMenuComponent, FacilityMobileComponent],
    templateUrl: "./facility.component.html",
    styleUrl: "./facility.component.scss"
})
export class FacilityComponent implements AfterViewInit {
    @Input() public facility: IFacility | null;

    private windowSizeService = inject(WindowSizeService);
    private facilityService = inject(FacilityService);
    private changeDetector = inject(ChangeDetectorRef);
    private analyticsService = inject(AnalyticsService);

    public globalMenuShowing: boolean = false;

    private destroy$ = inject(DestroyRef);

    public isDesktop: boolean = false;

    ngAfterViewInit(): void {
        combineLatest([
            this.facilityService.getFacility("test-facility").pipe(
                tap((facility) => {
                    if (facility) {
                        this.analyticsService.trackPageView(
                            { pageTitle: "Facility Page" },
                            { facility: facility.name }
                        );
                    }
                })
            ),
            this.windowSizeService.getWidth()
        ])
            .pipe(takeUntilDestroyed(this.destroy$))
            .subscribe(([facility, width]) => {
                this.isDesktop = this.checkIsDesktop(width);
                this.facility = facility || null;
                this.changeDetector.detectChanges();
            });
    }

    private checkIsDesktop(width: number): boolean {
        return width > Breakpoint.Desktop;
    }

    public openGlobalMenu(): void {
        this.globalMenuShowing = true;

        this.analyticsService.trackInteraction(
            { action: "click", location: "Facility Page", category: "button", label: "Opened global menu" },
            { facility: this.facility?.name }
        );
    }

    public closeGlobalMenu(): void {
        this.globalMenuShowing = false;

        this.analyticsService.trackInteraction(
            { action: "click", location: "Global Menu", category: "button", label: "Closed global menu" },
            { facility: this.facility?.name }
        );
    }
}
