<div class="tab-content" [ngClass]="{ 'can-edit': canEdit }" @fadeIn>
    <grainger-mobile-department-header
        text="Solutions"
        (clicked)="trackMobileBackClick('solutions')"
    ></grainger-mobile-department-header>

    <ng-container *ngIf="department">
        <section *ngIf="department.solutionsNote || canEdit" class="section solutions-note">
            <p
                (appInlineInput)="updateDepartmentData('solutionsNote', $event)"
                (inlineDelete)="updateDepartmentData('solutionsNote', null)"
                [rows]="6"
                [isNew]="!department.solutionsNote"
            >
                {{ department.solutionsNote ?? (canEdit ? "+ Add Solutions Note" : "") }}
            </p>
        </section>
        <section *ngIf="department.products?.length" class="section products">
            <h2 class="section__heading section__heading--large">Products</h2>
            <div class="products__grid desktop-only">
                <ul
                    *ngFor="
                        let productCols of department.products || [] | arrayToColumns: 'even';
                        let i = index;
                        let last = last
                    "
                    class="bullet-list link-list product-list"
                >
                    <li
                        *ngFor="let product of productCols; let j = index"
                        class="bullet-list__item link-list__item product-list__item"
                    >
                        <grainger-icon-list-item
                            [link]="product"
                            [fields]="{
                                name: 2,
                                url: 2
                            }"
                            (clicked)="product.url && trackClick('Clicked on product - ' + product.name, 'solutions')"
                            (inlineSave)="updateDepartmentData('products[' + (i * j + j) + ']', $event)"
                            (inlineDelete)="deleteDepartmentDataItem('products', i * j + j)"
                        ></grainger-icon-list-item>
                    </li>
                    <li *ngIf="canEdit && last">
                        <ng-container *ngTemplateOutlet="addProduct"></ng-container>
                    </li>
                </ul>
                <ul class="bullet-list link-list product-list" *ngIf="canEdit && !department.products?.length">
                    <li class="link-list__item product-list__item" *ngTemplateOutlet="addProduct"></li>
                </ul>
                <ng-template #addProduct let-index="index">
                    <ng-select
                        class="inline-add"
                        [items]="products$ | async"
                        [trackByFn]="trackProductsBy"
                        [minTermLength]="1"
                        placeholder="+ Add Product"
                        [loading]="productsLoading"
                        typeToSearchText="Search products"
                        [typeahead]="productsInput$"
                        bindLabel="name"
                        (change)="updateDepartmentData('products[' + (department.products?.length ?? 0) + ']', $event)"
                    >
                    </ng-select>
                </ng-template>
            </div>

            <ul class="bullet-list link-list product-list mobile-only">
                <li
                    *ngFor="let product of department.products; let i = index"
                    class="link-list__item product-list__item"
                >
                    <grainger-icon-list-item
                        [link]="product"
                        (clicked)="product.url && trackClick('Clicked on product - ' + product.name, 'solutions')"
                        (inlineSave)="updateDepartmentData('products[' + i + ']', $event)"
                        (inlineDelete)="deleteDepartmentDataItem('products', i)"
                        [fields]="{
                            name: 2,
                            url: 2
                        }"
                    ></grainger-icon-list-item>
                </li>
            </ul>
        </section>

        <section *ngIf="department.services?.length || canEdit" class="section services">
            <h2 class="section__heading services__heading">Services</h2>

            <a
                href="https://www.grainger.com/content/mc/services-and-solutions"
                target="_blank"
                class="arrow-resource-link"
                (click)="trackClick('Clicked on services landing page link', 'solutions')"
            >
                <span class="arrow-resource-link__icon"></span>
                <span class="arrow-resource-link__text"> Service and Solutions Landing Page </span>
            </a>
            <ul class="bullet-list service-list">
                <li *ngFor="let service of department.services; let i = index" class="link-list__item">
                    <grainger-icon-list-item
                        [link]="service"
                        [fields]="{
                            name: 2
                        }"
                        (inlineSave)="updateDepartmentData('services[' + i + ']', $event)"
                        (inlineDelete)="deleteDepartmentDataItem('services', i)"
                    ></grainger-icon-list-item>
                </li>
                <li *ngIf="canEdit" class="link-list__item">
                    <grainger-icon-list-item
                        [link]="{
                            name: '+ Add Service'
                        }"
                        [fields]="{
                            name: 2
                        }"
                        (inlineSave)="
                            updateDepartmentData('services[' + (department.services?.length ?? 0) + ']', $event)
                        "
                        [isNew]="true"
                    ></grainger-icon-list-item>
                </li>
            </ul>
        </section>

        <section *ngIf="department.suppliers?.length || canEdit" class="section suppliers">
            <grainger-supplier-accordion
                [suppliers]="department.suppliers ?? []"
                (analyticsEvent)="trackClick($event, 'solutions')"
            >
                <grainger-accordion-item
                    class="accordion-item"
                    *ngFor="let supplier of department.suppliers; let i = index; trackBy: trackSupplierBy"
                >
                    <ng-container accordion-heading>
                        <span
                            (appInlineInput)="updateDepartmentData('suppliers[' + i + '].name', $event)"
                            (inlineDelete)="deleteDepartmentDataItem('suppliers', i)"
                            [rows]="2"
                        >
                            {{ supplier.name }}
                        </span>
                    </ng-container>

                    <ng-container *ngIf="supplier.hasProducts || supplier.hasServices || canEdit" accordion-icons>
                        <div class="accordion-legend accordion-legend--no-labels">
                            <div *ngIf="supplier.hasServices" class="accordion-legend__item">
                                <div class="c-legend-icon c-legend-icon--service">
                                    <img
                                        src="assets/ui/icon-service-black.png"
                                        alt="Service Icon"
                                        class="accordion-legend__img"
                                    />
                                </div>
                            </div>

                            <div *ngIf="supplier.hasProducts" class="accordion-legend__item">
                                <div class="c-legend-icon c-legend-icon--product">
                                    <img
                                        src="assets/ui/icon-product-black.png"
                                        alt="Product Icon"
                                        class="accordion-legend__img"
                                    />
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container accordion-content>
                        <h3 class="accordion-content__subtitle">Supplier Details</h3>
                        <ng-container *ngIf="supplier.description?.length || canEdit">
                            <p
                                *ngFor="let description of supplier.description; let j = index"
                                class="accordion-content__copy"
                                (appInlineInput)="
                                    updateDepartmentData('suppliers[' + i + '].description[' + j + ']', $event)
                                "
                                [rows]="4"
                                (inlineDelete)="deleteDepartmentDataItem('suppliers[' + i + '].description', j)"
                            >
                                {{ description | capitalizeFirstLetter }}
                            </p>
                            <p
                                *ngIf="canEdit"
                                class="accordion-content__copy"
                                (appInlineInput)="
                                    updateDepartmentData(
                                        'suppliers[' + i + '].description[' + (supplier.description?.length ?? 0) + ']',
                                        $event
                                    )
                                "
                                [rows]="4"
                                [isNew]="true"
                            >
                                + Add Description
                            </p>
                        </ng-container>

                        <ul *ngIf="supplier.resources?.length || canEdit" class="link-list accordion-content__links">
                            <li
                                class="link-list__item accordion-content__link-item"
                                *ngFor="let resource of supplier.resources; let j = index"
                            >
                                <grainger-icon-list-item
                                    [link]="resource"
                                    [fields]="{
                                        name: 2,
                                        url: 2
                                    }"
                                    (clicked)="
                                        trackClick(
                                            'Clicked supplier link - ' + supplier.name + ' - ' + resource.name,
                                            'solutions'
                                        )
                                    "
                                    (inlineSave)="
                                        updateDepartmentData('suppliers[' + i + '].resources[' + j + ']', $event)
                                    "
                                    (inlineDelete)="deleteDepartmentDataItem('suppliers[' + i + '].resources', j)"
                                ></grainger-icon-list-item>
                            </li>
                            <grainger-icon-list-item
                                *ngIf="canEdit"
                                class="accordion-content__link-item"
                                [link]="{
                                    name: '+ Add Resource'
                                }"
                                [fields]="{
                                    name: 2,
                                    url: 2
                                }"
                                (inlineSave)="
                                    updateDepartmentData(
                                        'suppliers[' + i + '].resources[' + (supplier.resources?.length ?? 0) + ']',
                                        $event
                                    )
                                "
                                [isNew]="true"
                            ></grainger-icon-list-item>
                        </ul>

                        <div *ngIf="supplier.url || supplier.phoneNumber || canEdit" class="c-button">
                            <svg
                                class="inline-edit-icon url-edit-icon"
                                title="Edit"
                                *ngIf="canEdit && !supplierLink.isInputVisible"
                                (click)="$event.stopPropagation(); supplierLink.toggleInputElement(supplier.url || '')"
                            >
                                <use xlink:href="assets/ui/icon-edit.svg#icon"></use>
                            </svg>
                            <span
                                (appInlineInput)="updateDepartmentData('suppliers[' + i + '].url', $event)"
                                [rows]="2"
                                [hidden]="!canEdit || !supplierLink.isInputVisible"
                                [disableIcon]="true"
                                #supplierLink="input"
                            >
                                {{ supplier.url }}
                            </span>
                            <grainger-arrow-button
                                *ngIf="!supplierLink.isInputVisible"
                                class="accordion-content__button"
                                text="Visit Supplier"
                                [path]="supplier.url"
                                arrowDirection="right"
                                (clicked)="trackClick('Clicked supplier button - ' + supplier.name, 'solutions')"
                            ></grainger-arrow-button>
                            <span class="supplier__phone" *ngIf="supplier.phoneNumber || canEdit">
                                Phone:&nbsp;<span
                                    (appInlineInput)="updateDepartmentData('suppliers[' + i + '].phoneNumber', $event)"
                                    [rows]="2"
                                    [disableDelete]="true"
                                >
                                    {{ supplier.phoneNumber }}
                                </span>
                            </span>
                        </div>
                    </ng-container>
                </grainger-accordion-item>
            </grainger-supplier-accordion>
            <div class="c-supplier-add" *ngIf="canEdit">
                <ng-select
                    class="inline-add"
                    #supplierSelect
                    [items]="suppliers$ | async"
                    [trackByFn]="trackSuppliersBy"
                    [minTermLength]="1"
                    placeholder="+ Add Supplier"
                    [loading]="suppliersLoading"
                    typeToSearchText="Search suppliers"
                    [typeahead]="suppliersInput$"
                    bindLabel="name"
                    (change)="
                        supplierSelect.writeValue(null);
                        updateDepartmentData('suppliers[' + (department.suppliers?.length ?? 0) + ']', $event)
                    "
                >
                </ng-select>
            </div>
        </section>

        <div class="logo">
            <img src="assets/images/logo.svg" alt="Grainger Logo" class="logo__img" />
        </div>
    </ng-container>
</div>
