import {
    AfterViewInit,
    DestroyRef,
    Directive,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
    Renderer2,
    inject
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { debounceTime, tap } from "rxjs";
import { EditService } from "../services/edit.service";

@Directive({
    selector: "[appInlineInput]",
    exportAs: "input",
    standalone: true
})
export class InlineInputDirective implements AfterViewInit {
    private inputElement: HTMLTextAreaElement | null = null;
    private editIconElement: SVGElement | null = null;
    private deleteIconElement: SVGElement | null = null;
    private _value: string = "";
    private placeholder: string = "";
    private listenerCleanups: (() => void)[] = [];

    @HostBinding("class.inline-editable")
    private canEdit: boolean = false;

    @Output()
    public appInlineInput: EventEmitter<string> = new EventEmitter<string>();
    @Output()
    public inlineDelete: EventEmitter<void> = new EventEmitter<void>();

    @HostBinding("class.placeholder")
    @Input()
    public isNew = false;

    @Input() public rows: number = 1;
    @Input() public disableEdit = false;
    @Input() public disableIcon = false;
    @Input() public disableDelete = false;
    @Input() public alwaysShowButtons = false;

    private readonly destroyRef = inject(DestroyRef);
    private readonly editService = inject(EditService);

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private renderer: Renderer2
    ) {}

    ngAfterViewInit(): void {
        this.editService.isEditing$
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                debounceTime(100),
                tap((canEdit) => {
                    this.canEdit = !this.disableEdit && canEdit;
                    if (this.canEdit) {
                        if (!this.isInputVisible && (!this.isNew || this.alwaysShowButtons) && !this.disableIcon) {
                            this.createEditButton();
                            if (!this.disableDelete) {
                                this.createDeleteButton();
                            }
                        }
                    } else {
                        if (this.isInputVisible) {
                            this.toggleInputElement();
                        }

                        this.destroyEditButton();
                        this.destroyDeleteButton();
                    }
                })
            )
            .subscribe();
    }

    @HostListener("dblclick", ["$event"])
    public onDoubleClick(event: Event): void {
        if (this.canEdit) {
            event.stopPropagation();
            if (!this.isInputVisible) {
                this.toggleInputElement();
            }
        }
    }

    @HostListener("click", ["$event"])
    public onClick(event: Event): void {
        if (this.canEdit) {
            event.stopPropagation();
            // Show on click only if it's a new item
            if (this.isNew && !this.isInputVisible) {
                this.toggleInputElement();
            }
        }
    }

    @HostListener("document:click", ["$event"])
    public onOutsideClick(event: Event) {
        const target = event.target as HTMLElement;
        if (this.isInputVisible && !this.elementRef.nativeElement.contains(target)) {
            this.toggleInputElement();
        }
    }

    private createEditButton() {
        const svg: SVGElement = this.renderer.createElement("svg", "svg");
        svg.setAttribute("title", "Edit");
        svg.innerHTML = `<use xlink:href="assets/ui/icon-edit.svg#icon"></use>`;
        svg.classList.add("inline-edit-icon");
        this.renderer.insertBefore(this.elementRef.nativeElement, svg, this.elementRef.nativeElement.firstChild);
        this.editIconElement = svg;

        const clickListener = this.renderer.listen(svg, "click", () => this.toggleInputElement());
        this.listenerCleanups.push(clickListener);
    }

    private createDeleteButton() {
        const svg: SVGElement = this.renderer.createElement("svg", "svg");
        svg.setAttribute("title", "Delete");
        svg.innerHTML = `<use xlink:href="assets/ui/icon-trash.svg#icon"></use>`;
        svg.classList.add("inline-trash-icon");
        this.deleteIconElement = svg;

        this.renderer.insertBefore(this.elementRef.nativeElement, svg, this.elementRef.nativeElement.firstChild);

        const clickListener = this.renderer.listen(svg, "click", () => this.deleteItem());
        this.listenerCleanups.push(clickListener);
    }

    private destroyEditButton() {
        if (this.editIconElement) {
            this.renderer.removeChild(this.elementRef.nativeElement, this.editIconElement);
            this.editIconElement = null;
        }
    }

    private destroyDeleteButton() {
        if (this.deleteIconElement) {
            this.renderer.removeChild(this.elementRef.nativeElement, this.deleteIconElement);
            this.editIconElement = null;
        }
    }

    public toggleInputElement(valueOverride?: string): void {
        if (this.isInputVisible) {
            this.destroyInputElement();
            if ((!this.isNew || this.alwaysShowButtons) && !this.disableIcon) {
                this.createEditButton();
                if (!this.disableDelete) {
                    this.createDeleteButton();
                }
            }
        } else {
            this.destroyEditButton();
            this.destroyDeleteButton();
            this._value = valueOverride ?? (this.isNew ? "" : this.elementRef.nativeElement.innerText);
            this.placeholder = this.isNew ? valueOverride ?? this.elementRef.nativeElement.innerText : "";
            this.renderer.setProperty(this.elementRef.nativeElement, "innerText", "");
            this.createEditElements();
            this.inputElement!.focus();
        }
    }

    private createEditElements() {
        this.listenerCleanups = [];
        this.inputElement = this.createInputElement();
        const buttonContainer = this.createInputButtons();
        this.renderer.appendChild(this.elementRef.nativeElement, this.inputElement);
        this.renderer.appendChild(this.elementRef.nativeElement, buttonContainer);
    }

    private createInputElement(): HTMLTextAreaElement {
        const inputElement: HTMLTextAreaElement = this.renderer.createElement("textarea");
        inputElement.value = this._value;
        inputElement.placeholder = this.placeholder || "";
        inputElement.setAttribute("rows", String(this.rows));
        inputElement.classList.add("inline-input");

        const enterListener = this.renderer.listen(inputElement, "keydown.enter", () => this.saveInputValue());
        const escListener = this.renderer.listen(inputElement, "keydown.esc", () => this.toggleInputElement());
        this.listenerCleanups = this.listenerCleanups.concat(enterListener, escListener);

        return inputElement;
    }

    private createInputButtons(): HTMLDivElement {
        const container: HTMLDivElement = this.renderer.createElement("div");
        container.classList.add("inline-edit-buttons");

        const cancelButton: HTMLButtonElement = this.renderer.createElement("button");
        cancelButton.innerText = "Cancel";
        cancelButton.classList.add("btn", "inline-edit-cancel");
        cancelButton.addEventListener("click", (event) => {
            event.stopPropagation();
            this.toggleInputElement();
        });
        container.appendChild(cancelButton);

        const saveButton: HTMLButtonElement = this.renderer.createElement("button");
        saveButton.innerText = "Save";
        saveButton.classList.add("btn", "inline-edit-button");
        saveButton.addEventListener("click", (event) => {
            event.stopPropagation();
            this.saveInputValue();
        });
        container.appendChild(saveButton);

        const cancelListener = this.renderer.listen(cancelButton, "click", () => this.toggleInputElement());
        const saveListener = this.renderer.listen(saveButton, "click", () => this.saveInputValue());

        this.listenerCleanups = this.listenerCleanups.concat(cancelListener, saveListener);

        return container;
    }

    private destroyInputElement(): void {
        this.listenerCleanups.forEach((cleanup) => cleanup());
        this.listenerCleanups = [];
        if (this.inputElement) {
            this.renderer.removeChild(this.elementRef.nativeElement, this.inputElement);
            this.inputElement = null;
        }
        this.renderer.setProperty(
            this.elementRef.nativeElement,
            "innerText",
            this.isNew ? this.placeholder : this._value
        );
    }

    private saveInputValue(): void {
        const value = this.inputElement!.value;
        this._value = value;
        this.toggleInputElement();
        this.appInlineInput.emit(value);
    }

    private deleteItem(): void {
        this.inlineDelete.emit();
    }

    @HostBinding("class.inline-editing")
    public get isInputVisible(): boolean {
        return this.inputElement !== null;
    }

    @HostBinding("title")
    public get title() {
        return this.canEdit ? "Double click to edit" : "";
    }

    @HostBinding("style.cursor")
    public get cursor() {
        return this.isNew ? (this.isInputVisible ? "auto" : "pointer") : "auto";
    }
}
