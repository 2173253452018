import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BaseDepartmentComponent } from "../../components/for-extension-only/base-department/base-department.component";
import { SubpageHeaderComponent } from "../../components/subpage-header/subpage-header.component";
import { InlineInputDirective } from "../../core/directives/inline-input.directive";

@Component({
    selector: "grainger-mobile-department",
    standalone: true,
    imports: [CommonModule, SubpageHeaderComponent, RouterModule, InlineInputDirective],
    templateUrl: "./mobile-department.component.html",
    styleUrl: "./mobile-department.component.scss"
})
export class MobileDepartmentComponent extends BaseDepartmentComponent {}
