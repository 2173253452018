import { provideHttpClient, withFetch, withInterceptors } from "@angular/common/http";
import { ApplicationConfig } from "@angular/core";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter, withRouterConfig } from "@angular/router";
import { appRoutes, routerExtraOptions } from "./app.routes";
import { errorInterceptor } from "./core/interceptors/auth.interceptor";
import { loadingInterceptor } from "./core/interceptors/loading.interceptor";
import { tokenInterceptor } from "./core/interceptors/token.interceptor";
import { CUSTOM_GLOBAL_PROVIDERS } from "./core/providers/custom-global.providers";

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(appRoutes, withRouterConfig(routerExtraOptions)),
        ...CUSTOM_GLOBAL_PROVIDERS,
        provideAnimations(),
        provideHttpClient(withFetch(), withInterceptors([tokenInterceptor, errorInterceptor, loadingInterceptor]))
    ]
};
