import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IProduct } from "@grainger/common/definitions/product";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class ProductsService {
    constructor(private http: HttpClient) {}

    public getProducts(searchText: string) {
        return this.http.get<IProduct[]>(`${environment.baseUrl}/products`, {
            params: {
                searchText
            }
        });
    }
}
