<div class="tab-content" [ngClass]="{ 'can-edit': canEdit }" @fadeIn>
    <grainger-mobile-department-header
        text="Insights"
        (clicked)="trackMobileBackClick('insights')"
    ></grainger-mobile-department-header>

    <ng-container *ngIf="department">
        <section class="section insights" *ngIf="department.insights || canEdit">
            <div class="insights__details">
                <h2 class="section__heading section__heading--large">Industry Insights</h2>
                <div *ngIf="department.insights?.industryInsights?.length || canEdit" class="section__content">
                    <div
                        *ngFor="let insight of department.insights?.industryInsights; let i = index"
                        class="section__copy"
                        [innerHtml]="insight"
                        (appInlineInput)="updateDepartmentData('insights.industryInsights[' + i + ']', $event)"
                        (inlineDelete)="deleteDepartmentDataItem('insights.industryInsights', i)"
                        [rows]="6"
                    ></div>
                    <div
                        *ngIf="canEdit"
                        class="section__copy"
                        (appInlineInput)="
                            updateDepartmentData(
                                'insights.industryInsights[' +
                                    (department.insights?.industryInsights?.length ?? 0) +
                                    ']',
                                $event
                            )
                        "
                        [rows]="6"
                        [isNew]="true"
                    >
                        + Add Industry Insight
                    </div>
                </div>
            </div>

            <div *ngIf="department.insights?.competitorInsights?.length || canEdit" class="competitor-insights">
                <h3 class="competitor-insights__heading">Competitor Insights</h3>
                <a
                    href="https://grainger.sharepoint.com/sites/CSM-CorpStrategy/SitePages/Competitor-Battle-Cards.aspx?xsdata=MDV8MDJ8fGRmNTExOTZlMGZiZTRiMDdhNGEyMDhkYzQzOTc2NTVkfDQ4ZDFkY2I2YmNjYzQzNjVhYzdmYjkzN2E3ZjdmZDcxfDB8MHw2Mzg0NTk1NjQ2OTk1MjYzMDZ8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxMMk5vWVhSekx6RTVPbTFsWlhScGJtZGZUMVJWTkZwVVJYcFBSMGwwV2tSRk0xcHBNREJOUkVwb1RGUm9hMDlIVlhST1ZHaHBXbTFSZUU1VVVYcGFWMUYzUUhSb2NtVmhaQzUyTWk5dFpYTnpZV2RsY3k4eE56RXdNelU1TmpZNU1qWXl8ZTRkNzEyOGIyNzE4NDc3YWE0YTIwOGRjNDM5NzY1NWR8NjU1ZWFiNGVkMjZiNDQ4M2JjNDVkNGEyZDliYTA2ZjI%3D&sdata=cUJ2aWYvUWowblNMYUd5TmxRcUQxMWNaTWdCLzdJcHozbGZXRXdIT29mRT0%3D&ovuser=48d1dcb6-bccc-4365-ac7f-b937a7f7fd71%2Clori.jones%40grainger.com&OR=Teams-HL&CT=1710359677580&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI0OS8yNDAyMDIwNTUxNiIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D"
                    target="_blank"
                    class="arrow-resource-link"
                    (click)="trackClick('Clicked competitor insights link', 'insights')"
                >
                    <span class="arrow-resource-link__icon"></span>
                    <span class="arrow-resource-link__text">Learn more about our competitors</span>
                </a>
                <ul class="bullet-list insights-list">
                    <li
                        *ngFor="let insight of department.insights?.competitorInsights; let i = index"
                        class="bullet-list__item insights-list__item"
                    >
                        <grainger-icon-list-item
                            [link]="{ name: insight }"
                            [fields]="{
                                name: 2
                            }"
                            (inlineSave)="updateDepartmentData('insights.competitorInsights[' + i + ']', $event.name)"
                            (inlineDelete)="deleteDepartmentDataItem('insights.competitorInsights', i)"
                        ></grainger-icon-list-item>
                    </li>
                    <li *ngIf="canEdit" class="bullet-list__item insights-list__item">
                        <grainger-icon-list-item
                            [link]="{ name: '+ Add Competitor Insight' }"
                            [fields]="{
                                name: 2
                            }"
                            (inlineSave)="
                                updateDepartmentData(
                                    'insights.competitorInsights[' +
                                        (department.insights?.competitorInsights?.length ?? 0) +
                                        ']',
                                    $event.name
                                )
                            "
                            [isNew]="true"
                        ></grainger-icon-list-item>
                    </li>
                </ul>
            </div>
        </section>

        <div class="logo">
            <img src="assets/images/logo.svg" alt="Grainger Logo" class="logo__img" />
        </div>
    </ng-container>
</div>
