import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BaseDepartmentComponent } from "../for-extension-only/base-department/base-department.component";

@Component({
    selector: "grainger-department-section-selector",
    standalone: true,
    imports: [CommonModule, RouterModule],
    templateUrl: "./department-section-selector.component.html",
    styleUrl: "./department-section-selector.component.scss"
})
export class DepartmentSectionSelectorComponent extends BaseDepartmentComponent {}
