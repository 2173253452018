import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "arrayToColumns",
    standalone: true,
    pure: true
})
export class ArrayToColumnsPipe implements PipeTransform {
    transform<T>(array: T[] | null | undefined, itemsPerCol: number | "even"): T[][] {
        if (!array) {
            return [];
        }

        if (itemsPerCol === "even") {
            const middle = Math.ceil(array.length / 2);

            return [array.slice(0, middle), array.slice(middle)];
        } else {
            const columns: T[][] = [];
            array.forEach((item, i) => {
                if (i === 0 || i % itemsPerCol === 0) {
                    columns.push([item]);
                } else {
                    columns[columns.length - 1].push(item);
                }
            });

            return columns;
        }
    }
}
