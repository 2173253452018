<div *ngIf="department" class="tab-content" [ngClass]="{ 'can-edit': canEdit }" @fadeIn>
    <grainger-mobile-department-header
        text="Overview"
        (clicked)="trackMobileBackClick('overview')"
    ></grainger-mobile-department-header>

    <section *ngIf="department.dailyOperations?.length || canEdit" class="section operations">
        <h2 class="section__heading section__heading--large">Daily Operations</h2>
        <div class="section__content">
            <div
                *ngFor="let paragraph of department.dailyOperations; let i = index"
                class="section__copy"
                (appInlineInput)="updateDepartmentData('dailyOperations[' + i + ']', $event)"
                (inlineDelete)="deleteDepartmentDataItem('dailyOperations', i)"
                [rows]="4"
            >
                {{ paragraph }}
            </div>
            <div
                *ngIf="canEdit"
                class="section__copy"
                (appInlineInput)="
                    updateDepartmentData('dailyOperations[' + (department.dailyOperations?.length ?? 0) + ']', $event)
                "
                [rows]="4"
                [isNew]="true"
            >
                + Add Daily Operation
            </div>
        </div>
    </section>

    <ng-container *ngIf="department.targetRolesAndResponsibilities as rolesData">
        <section
            *ngIf="
                rolesData.targetRoles?.length ||
                rolesData.areasOfResponsibility?.length ||
                rolesData.commonAreasOfOpportunity?.length
            "
            class="section roles"
        >
            <h1 class="section__heading roles__heading">Target Roles & Responsibilities</h1>
            <div class="roles__grid">
                <div *ngIf="rolesData.targetRoles?.length || canEdit" class="c-roles-list c-roles-list--1">
                    <h2 class="roles-list__heading">Title</h2>
                    <ul class="bullet-list roles-list">
                        <li
                            *ngFor="let title of department.targetRolesAndResponsibilities?.targetRoles; let i = index"
                            class="bullet-list__item roles-list__item"
                        >
                            <grainger-icon-list-item
                                [link]="{ name: title }"
                                [fields]="{
                                    name: 2
                                }"
                                (inlineSave)="
                                    updateDepartmentData(
                                        'targetRolesAndResponsibilities.targetRoles[' + i + ']',
                                        $event.name
                                    )
                                "
                                (inlineDelete)="
                                    deleteDepartmentDataItem('targetRolesAndResponsibilities.targetRoles', i)
                                "
                            ></grainger-icon-list-item>
                        </li>
                        <li *ngIf="canEdit" class="roles-list__item">
                            <grainger-icon-list-item
                                [link]="{ name: '+ Add Title' }"
                                [fields]="{
                                    name: 2
                                }"
                                [isNew]="true"
                                (inlineSave)="
                                    updateDepartmentData(
                                        'targetRolesAndResponsibilities.targetRoles[' +
                                            (rolesData?.targetRoles?.length ?? 0) +
                                            ']',
                                        $event.name
                                    )
                                "
                            ></grainger-icon-list-item>
                        </li>
                    </ul>
                </div>
                <div *ngIf="rolesData.areasOfResponsibility?.length || canEdit" class="c-roles-list c-roles-list--2">
                    <h2 class="roles-list__heading">Areas of Responsibility</h2>
                    <ul class="bullet-list roles-list">
                        <li
                            *ngFor="let area of rolesData.areasOfResponsibility; let i = index"
                            class="bullet-list__item roles-list__item"
                        >
                            <grainger-icon-list-item
                                [link]="{ name: area }"
                                [fields]="{
                                    name: 6
                                }"
                                (inlineSave)="
                                    updateDepartmentData(
                                        'targetRolesAndResponsibilities.areasOfResponsibility[' + i + ']',
                                        $event.name
                                    )
                                "
                                (inlineDelete)="
                                    deleteDepartmentDataItem('targetRolesAndResponsibilities.areasOfResponsibility', i)
                                "
                            ></grainger-icon-list-item>
                        </li>
                        <li *ngIf="canEdit" class="roles-list__item">
                            <grainger-icon-list-item
                                [link]="{ name: '+ Add Area of Responsibility' }"
                                [fields]="{
                                    name: 6
                                }"
                                [isNew]="true"
                                (inlineSave)="
                                    updateDepartmentData(
                                        'targetRolesAndResponsibilities.areasOfResponsibility[' +
                                            (rolesData?.areasOfResponsibility?.length ?? 0) +
                                            ']',
                                        $event.name
                                    )
                                "
                            ></grainger-icon-list-item>
                        </li>
                    </ul>
                </div>
                <div *ngIf="rolesData.commonAreasOfOpportunity?.length || canEdit" class="c-roles-list c-roles-list--3">
                    <h2 class="roles-list__heading">Common Areas of Opportunity</h2>
                    <ul class="bullet-list roles-list">
                        <li
                            *ngFor="let area of rolesData.commonAreasOfOpportunity; let i = index"
                            class="bullet-list__item roles-list__item"
                        >
                            <grainger-icon-list-item
                                [link]="{ name: area }"
                                [fields]="{
                                    name: 6
                                }"
                                (inlineSave)="
                                    updateDepartmentData(
                                        'targetRolesAndResponsibilities.commonAreasOfOpportunity[' + i + ']',
                                        $event.name
                                    )
                                "
                                (inlineDelete)="
                                    deleteDepartmentDataItem(
                                        'targetRolesAndResponsibilities.commonAreasOfOpportunity',
                                        i
                                    )
                                "
                            ></grainger-icon-list-item>
                        </li>
                        <li *ngIf="canEdit" class="roles-list__item">
                            <grainger-icon-list-item
                                [link]="{ name: '+ Add Area of Opportunity' }"
                                [fields]="{
                                    name: 6
                                }"
                                [isNew]="true"
                                (inlineSave)="
                                    updateDepartmentData(
                                        'targetRolesAndResponsibilities.commonAreasOfOpportunity[' +
                                            (rolesData?.commonAreasOfOpportunity?.length ?? 0) +
                                            ']',
                                        $event.name
                                    )
                                "
                            ></grainger-icon-list-item>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    </ng-container>

    <section class="section influencers">
        <ng-container *ngIf="department.industryInfluencers?.length || canEdit">
            <h3 class="section__heading section__heading--small influencers__heading">Industry Influencers</h3>
            <ul class="link-list influencers-list">
                <li
                    *ngFor="let influencer of department.industryInfluencers; let i = index"
                    class="link-list__item influencers-list__item"
                    [ngClass]="{ 'influencers-list__item--no-link': !influencer.url }"
                >
                    <grainger-icon-list-item
                        *ngIf="influencer.name || influencer.description || influencer.url"
                        [fields]="{
                            name: true,
                            url: true
                        }"
                        [link]="{ name: influencer.description || influencer.name || '', url: influencer.url }"
                        (clicked)="
                            influencer.url &&
                                trackClick(
                                    'Clicked on industry influencer - ' +
                                        (influencer.name || influencer.description || influencer.url),
                                    'Overview'
                                )
                        "
                        (inlineSave)="updateDepartmentData('industryInfluencers[' + i + ']', $event)"
                        (inlineDelete)="deleteDepartmentDataItem('industryInfluencers', i)"
                    ></grainger-icon-list-item>
                </li>
                <li *ngIf="canEdit" class="roles-list__item">
                    <grainger-icon-list-item
                        [link]="{ name: '+ Add Industry Influencer' }"
                        [fields]="{
                            name: true,
                            url: true
                        }"
                        [isNew]="true"
                        (inlineSave)="
                            updateDepartmentData(
                                'industryInfluencers[' + (department.industryInfluencers?.length ?? 0) + ']',
                                $event.name
                            )
                        "
                    ></grainger-icon-list-item>
                </li>
            </ul>
        </ng-container>
    </section>

    <section *ngIf="department.elevatorPitch?.length || canEdit" class="section elevator-pitch">
        <h3 class="section__heading section__heading--small elevator-pitch__heading">Elevator Pitch</h3>
        <ul class="question-list">
            <li *ngFor="let question of department.elevatorPitch; let i = index" class="question-list__item">
                <div class="icon-with-text">
                    <img class="icon" src="assets/ui/icon-question-mark.svg" />
                    <span
                        class="text"
                        [rows]="2"
                        (appInlineInput)="updateDepartmentData('elevatorPitch[' + i + ']', $event)"
                        (inlineDelete)="deleteDepartmentDataItem('elevatorPitch', i)"
                    >
                        {{ question }}
                    </span>
                </div>
            </li>
            <li class="question-list__item" *ngIf="canEdit">
                <div class="icon-with-text">
                    <span></span>
                    <span
                        class="text"
                        (appInlineInput)="
                            updateDepartmentData(
                                'elevatorPitch[' + (department.elevatorPitch?.length ?? 0) + ']',
                                $event
                            )
                        "
                        [isNew]="true"
                    >
                        + Add Elevator Pitch</span
                    >
                </div>
            </li>
        </ul>
    </section>

    <div class="logo">
        <img src="assets/images/logo.svg" alt="Grainger Logo" class="logo__img" />
    </div>
</div>
