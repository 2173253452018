export enum ValidationErrorType {
    Required = "required",
    Email = "email",
    PasswordMismatch = "passwordMismatch",
    FieldMismatch = "fieldMismatch",
    Min = "min",
    Max = "max",
    MinLength = "minlength",
    MaxLength = "maxlength",
    InvalidEmailDomain = "invalidEmailDomain",
}
