<div class="dollhouse-wrapper"></div>
<div
    class="content-wrapper"
    [ngStyle]="
        !!department
            ? {
                  backgroundImage: 'url(assets/dollhouses/mobile/' + department.facility?.urlSlug + '.png)'
              }
            : {}
    "
>
    <div class="screen"></div>
    <grainger-subpage-header
        *ngIf="!!department && department.pillarInfo as pillarInfo"
        class="popup-header"
        b
        [button]="{ name: 'Back to Map', url: '' }"
        [dropdownMenu]="{
            title: pillarInfo.pillarName || '',
            links: departmentLinks.length ? departmentLinks : []
        }"
        (buttonClicked)="trackHeaderClick('Clicked back to map')"
    >
        <h1 slot="left" class="popup-header__heading">
            {{ pillarInfo.pillarName | titlecase }}
        </h1>
    </grainger-subpage-header>

    <main *ngIf="!!department" class="content">
        <div *ngIf="router.url === '/department/' + department.urlSlug" class="header">
            <h1 *ngIf="department.name" class="header__title">
                {{ department.name }}
            </h1>
            <p
                *ngIf="department.description || canEdit"
                class="header__description"
                (appInlineInput)="updateDepartmentDescription($event)"
                [rows]="8"
            >
                {{ department.description || "+ Add Description" }}
            </p>
        </div>

        <div class="c-section-content">
            <router-outlet></router-outlet>
        </div>
    </main>
</div>
