import { Component, DestroyRef, EventEmitter, inject } from "@angular/core";
import { CustomAnimations } from "../../core/animations/animations";
import { IPopover } from "../../core/interfaces/popover.interface";

@Component({
    selector: "grainger-confirm-delete-popover",
    templateUrl: "./confirm-delete-popover.component.html",
    styleUrls: ["./confirm-delete-popover.component.scss"],
    standalone: true,
    animations: [CustomAnimations.fadeIn("0.3s")]
})
export class ConfirmDeletePopoverComponent implements IPopover {
    public close: EventEmitter<string> = new EventEmitter<string>();

    public destroyRef = inject(DestroyRef);

    closePopover(reason?: string) {
        this.close.emit(reason);
    }
}
