import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";

@Component({
    selector: "grainger-pagination",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./pagination.component.html",
    styleUrl: "./pagination.component.scss"
})
export class PaginationComponent implements AfterViewInit, OnChanges {
    @Output() public pageChanged: EventEmitter<number> = new EventEmitter<number>();

    @Input() public totalPages: number = 1;
    @Input() public currentPage: number = 1;
    @Input() public maxShownPages: number = 9;

    public shownPages: number[] = [];
    private pageList: number[] = [];

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["totalPages"] || changes["currentPage"] || changes["maxShownPages"]) {
            this.updatePagination();
        }
    }

    ngAfterViewInit(): void {
        if (this.currentPage > this.totalPages) {
            throw new Error("Current page cannot be greater than total pages");
        }
    }

    public updatePagination(): void {
        this.pageList = this.generatePageList(this.totalPages);

        this.shownPages = this.getPagesToShow(this.pageList);
    }

    public goToPage(page: number): void {
        this.pageChanged.emit(page);
    }

    public prevPage() {
        if (!this.isAtStart) {
            this.goToPage(this.currentPage - 1);
        }
    }

    public nextPage() {
        if (!this.isAtEnd) {
            this.goToPage(this.currentPage + 1);
        }
    }

    private generatePageList(pageCount: number): number[] {
        return Array.from(Array(pageCount).keys(), (val) => val + 1);
    }

    private getPagesToShow(pages: number[]): number[] {
        const start = Math.max(
            0,
            Math.min(this.currentPage - Math.floor(this.maxShownPages / 2), this.totalPages - this.maxShownPages)
        );
        const end = Math.min(start + this.maxShownPages, this.totalPages);

        if (pages.length <= this.maxShownPages) {
            return pages;
        } else {
            return pages.slice(start, end);
        }
    }

    public get hasPreviousItems(): boolean {
        return this.shownPages.length > 1 && this.shownPages[0] !== 1;
    }

    public get hasNextItems(): boolean {
        return this.shownPages.length > 1 && this.shownPages[this.shownPages.length - 1] !== this.totalPages;
    }

    public get isAtStart(): boolean {
        return this.currentPage === 1;
    }

    public get isAtEnd(): boolean {
        return this.currentPage >= this.totalPages;
    }

    public get hasMultiplePages(): boolean {
        return this.totalPages > 1;
    }
}
