<grainger-form-page-wrapper>
    <h1 class="form__heading">Register</h1>
    <form *ngIf="form" class="form" [formGroup]="form">
        <div class="c-form-fields">
            <grainger-form-control class="form-control">
                <input type="text" formControlName="email" placeholder="Email" appInput="Email" />
            </grainger-form-control>
            <grainger-form-control class="form-control">
                <input type="password" formControlName="password" placeholder="Password" appInput="Password" />
            </grainger-form-control>

            <grainger-form-control class="form-control">
                <input
                    type="password"
                    formControlName="confirmPassword"
                    placeholder="Confirm Password"
                    appInput="Password confirmation"
                />
            </grainger-form-control>

            <div class="two-col">
                <grainger-form-control>
                    <grainger-select
                        appInput="Sales Region / Function"
                        label="Sales Region / Function"
                        formControlName="regionOrFunction"
                        [options]="regionOrFunctionOptions"
                    ></grainger-select>
                </grainger-form-control>
                <grainger-form-control>
                    <grainger-select
                        appInput="District / Team"
                        label="District / Team"
                        formControlName="district"
                        [options]="districtOptions"
                    ></grainger-select>
                </grainger-form-control>
            </div>
        </div>

        <div class="c-buttons">
            <grainger-arrow-button
                class="form-btn"
                text="Register"
                [handleInParent]="true"
                (clicked)="register()"
                arrowDirection="right"
            ></grainger-arrow-button>

            <div class="form-change-link">
                <span class="form-change-link__label">Already have an account?</span>
                <a
                    class="form-change-link__text"
                    [routerLink]="['/', 'login']"
                    (click)="trackClick('Clicked log in', 'Register Page')"
                    >Log in</a
                >
            </div>
        </div>

        <p *ngIf="hasSubmissionError" class="submission-error">
            {{ submissionError }}
        </p>
    </form>
</grainger-form-page-wrapper>
