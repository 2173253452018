<main class="form-page">
    <div class="form-page__content">
        <div class="logo">
            <img src="assets/images/logo.svg" alt="Grainger Logo" class="logo__img" />
        </div>
        <div class="form-page__content-inner">
            <ng-content></ng-content>
        </div>
    </div>
</main>
